.dash-card-body {
  width: 33.33%;
}

.dash-card-body-two {
  width: 33.33%;
}

.inner {
  width: 100%;
  border: 1px solid green;
}

.top {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* justify-content: space-between; */
}

.inlines {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.virtual {
  /* border-radius: 10px;
      width: 82%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
      box-shadow: 0 0.125rem 0.25rem rgb(2%); */
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 0.125rem 0.25rem rgb(2%);
}

.virtual2 {

  height: 90%;
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 0.125rem 0.25rem rgb(2%);
}

.virtual2 h3 {
  font-size: 30px;
  color: white;
  font-weight: 500;
}

.bank {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bank h4 {
  text-transform: capitalize;
  text-align: center;
}

.virtual span {
  font-size: 25px;
}

.virtual p {
  font-size: 17px;
  text-align: center;
}

.bankdetails {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding-top: 30px;
  padding: 30px;
}



.carousel {
  display: flex;
  overflow: hidden;
  width: 85%;
  gap: 20px;
  border-radius: 20px 20px 20px 20px;
  /* width: 31%; */
}

.carousel .dash-card-body-3,
.carousel .dash-card-body-2 {
  flex: 0 0 100%;
}



/* .slide-animation{
    animation: 20s slider infinite;
} */

@keyframes slide {
  0% {
    left: 0;
  }

  20% {
    left: -105%;
  }


}

.slide-animation {
  animation: slide 7s linear infinite;
}

/* @keyframes slide {
  50% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%);
  }
}

.slide-animation {
  animation: slide 10s linear infinite;
} */


@media screen and (max-width:1000px) {
  .top {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

}

@media screen and (max-width:768px) {
  .top {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    position: center;
  }

}
/*reponsiveness*/

@media only screen and (max-width: 599px) {
  .faq-main .btn-link {
    font-size: 0.8rem;
  }
  .main-menu {
    z-index: 2999999999;
    width: 260px;
  }
  .faq-bg {
    padding: 1rem;
  }
  .home-footer.footer {
    padding-bottom: 5rem;
  }
  .header-background {
    min-height: 85vh;
  }
  /*.toggle-password{*/
  /*    bottom:20px;*/
  /*    right:0%;*/
  /*    transform: translate(-50%,-50%);*/
  /*}*/
  .mb-7 {
    margin-bottom: 7rem !important;
  }
  .faq-main .card .card-header {
    overflow-x: auto;
  }
  .home-footer .footer-logo img {
    width: 60%;
  }
  .login-section {
    margin-bottom: 5rem;
  }
  .footer-p {
    text-align: center;
  }
  .steady-table-card.table-card {
    min-height: 0;
  }
  .table th,
  .table td {
    padding: 0.5rem;
  }
  .ad-links .btn-custom-blue {
    line-height: 40px;
  }
  .table td .btn-custom-blue {
    padding: 0px;
  }
  .dash-card-analysis h6,
  .dash-card-analysis strong {
    width: 120px;
  }
  .stash-btn {
    max-width: 100px;
  }
  .spaced-table tbody tr {
    box-shadow: 0rem 0.5rem 0.5rem rgba(0, 0, 0, 0.05);
  }

  .mobile-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .react-bootstrap-table-page-btns-ul {
    margin-right: 0px;
  }
  .vertical-layout.vertical-menu-modern.menu-flipped .main-menu {
    left: -30px;
  }

  .main-menu.menu-dark,
  .main-menu.menu-dark .navigation,
  .main-menu.menu-dark .navigation > li > ul {
    left: -30px;
  }

  .fixed-navbar.menu-expanded.menu-open .main-menu.menu-dark,
  .fixed-navbar.menu-expanded.menu-open .main-menu.menu-dark .navigation,
  .fixed-navbar.menu-expanded.menu-open
    .main-menu.menu-dark
    .navigation
    > li
    > ul {
    left: 0px;
  }

  /* mobile menu */
  .header-navbar .navbar-header .navbar-brand {
    position: absolute;
    top: 0;
    left: 10px;
    padding-top: 0.6rem;
    transform: translate(0);
  }

  .dashboard-callout {
    padding: 10px;
  }

  .mb-btn-wrapper.steady-btn-wrapper,
  .mb-btn-wrapper.bg-btn-wrapper {
    width: 100%;
  }

  .avatar img {
    margin-bottom: 3px;
  }

  .main-menu.menu-dark .navigation > li:first-of-type {
    border-bottom: 1px solid #c2c2c2;
  }

  .mobile-profile-nav {
    padding-bottom: 30px !important;
  }

  .table-status {
    font-size: 1.2rem;
  }

  .dash-card-analysis h6 {
    width: 105px;
  }

  .modal {
    z-index: 2000000000;
  }

  .modal-backdrop {
    z-index: 2000000000;
  }

  .css-e110bw {
    z-index: 200000000000000000000 !important;
    top: 0 !important;
    left: 0 !important;
  }
  .swal-overlay {
    z-index: 4000000000;
  }
  .modal-content h4 {
    font-size: 15px;
  }

  .steady-save-modal .modal-content,
  .instant-save-modal .modal-content {
    padding: 1.5rem 0.5rem;
    border-radius: 2rem;
  }

  .header-navbar .navbar-header .menu-toggle {
    position: relative;
    top: 58%;
  }

  .is-modal-form .btn-custom-blue {
    flex-basis: 60%;
    font-size: 1rem;
  }

  .menu-title {
    color: white;
  }

  .lock-form input.form-control,
  .lock-form select.form-control {
    color: #606060;
    font-size: 0.9rem;
    padding-left: 0rem;
  }
  .ad-container {
    padding: 0;
  }
  .ad-container .ad-placeholder {
    width: 17rem;
    padding: 1rem;
    font-size: 0.8rem;
    min-height: 23rem;
    background-color: white;
    text-align: center;
    border-radius: 0.8rem;
    margin-bottom: 2rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }

  .ad-container .ad-placeholder:hover {
    transform: scale(1.1);
  }

  .ad-container .ad-placeholder.active {
    transform: scale(1.1);
  }

  .ad-container .ad-desc-placeholder {
    margin-bottom: 0;
  }

  .btn-custom-blue {
    padding: 0px;
  }

  .dashboard-callout label {
    margin: 0px 0px;
  }

  .ad-container-modal .modal-content {
    padding-top: 2rem;
    background-color: white;
    background-image: none;
    border-radius: 1rem;
  }

  /*whatsapp section*/
  .whatsapp .btn-whatsapp {
    padding: 1.5rem 2rem;
  }

  /*bank card */
  .bank-card {
    border-radius: 1rem;
    min-height: 150px;
    width: 90%;
    margin: 1rem auto !important;
    padding: 0.4rem 0.5rem;
    box-shadow: 0.5rem 0.5rem 0.2rem rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
  }

  .header-navbar .navbar-header .navbar-brand .brand-logo {
    width: 130px;
  }

  .loading {
    top: 50%;
    left: 40%;
  }

  /* .dash-card {
        min-height: 200px;
    } */

  .dash-card-img {
    width: 5rem;
  }

  .dash-btn {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 2em;
  }

  .dash-action h5 {
    font-size: 10px;
  }

  .dash-ads .dash-action a {
    font-size: 8px;
  }

  .dash-action {
    width: 100px;
  }

  .right-action {
    left: 40%;
  }

  .error-section .error-header {
    font-size: 2rem;
  }

  .error-section .error-btn {
    /*padding: .5rem 1.5rem;*/
    font-size: 1rem;
  }

  .hero-carousel.owl-carousel .owl-nav button.owl-prev,
  .hero-carousel.owl-carousel .owl-nav button.owl-next {
    color: white;
    width: 3rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    height: 3rem;
    border-radius: 0;
    background-color: #367bff;
  }
}

@media only screen and (min-width: 600px) and (max-width: 899px) {
  .header-background {
    height: 780px;
    background-position: top right;
  }
  .blue-round-btn {
    padding: 0px;
  }
  .header-navbar .navbar-header .navbar-brand {
    left: 12%;
  }
  .navbar-semi-dark .navbar-nav .mobile-menu {
    margin-top: 13px;
  }
  .dash-card-img {
    width: 60px;
    margin: 0 auto;
  }
  .mb-details-container,
  .mb-btn-wrapper {
    margin-right: 15px;
  }
  .modal-body {
    padding: 3rem;
  }
  .ss-action-details {
    min-width: 200px;
  }
  .header-navbar .navbar-header .navbar-brand .brand-logo {
    padding-top: 3px;
    width: 150px;
  }

  .login-background {
    background-image: none;
  }

  .secure-section {
    position: absolute;
    bottom: 2%;
    left: 5%;
    vertical-align: middle;
    font-size: 1.3rem;
    font-family: "Circular Std", "Open Sans", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
  }

  .secure-section img {
    width: 2rem;
  }

  html body .content.app-content {
    margin-left: 0%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  html body .content.app-content {
    margin-left: 18%;
    background-color: #f7fbff;
  }

  .welcome-text {
    color: #103366;
    font-family: "Gilroy Bold", "Open Sans", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    letter-spacing: 0px;
    font-size: 1.5rem;
    position: absolute;
    top: 7%;
    left: 8%;
  }

  .login-background {
    background-image: none;
  }

  .secure-section {
    position: absolute;
    bottom: 2%;
    left: 8%;
    vertical-align: middle;
    font-size: 1.3rem;
    font-family: "Circular Std", "Open Sans", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
  }

  .spaced-table th,
  .spaced-table td {
    padding: 0.75rem 0.75rem !important;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .content {
    margin-left: 18%;
  }
  .card-container .card {
    width: 90%;
  }
  html body .content .content-wrapper {
    padding-top: 3rem;
  }
  .dash-card h3 {
    font-size: 1.2rem;
  }
  .mb-btn-wrapper button {
    padding: 1rem 1rem 1rem 2.5rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    border-radius: 5rem;
    transform: translate(0);
    cursor: pointer;
    transition: all 0.5s ease;
  }

  .btn-blue-gradient-2 img {
    position: absolute;
    top: 0.7rem;
    left: 0.4rem;
  }

  .blue-card-heading {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (min-width: 1300px) {
  .saved-text {
    width: 80%;
  }

  .active-text {
    width: 100%;
  }

  html body .content.app-content {
    margin-left: 18%;
    background-color: #f7fbff;
  }

  html body .content .content-wrapper {
    padding-top: 0rem;
  }
  .spaced-table th,
  .spaced-table td {
    padding: 0.75rem 0.75rem !important;
  }
}

@media all and (min-width: 768px) {
  .header-navbar .navbar-container ul.nav li a.menu-toggle {
    padding: 0px;
  }
  .navbar-semi-dark .navbar-nav li.nav-item {
    display: flex;
    align-items: center;
  }
  .header-navbar .navbar-container ul.nav li a.dropdown-user-link {
    margin-right: 0px;
  }
  .nav .nav-item .nav-link {
    display: flex;
    align-items: center;
  }
  .navbar-nav .nav-link {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  .main-menu {
    transform: translate3d(-240px, 0, 0);
    transition: all 0.3s ease-in-out;
  }
  .main-menu.active {
    transform: translate3d(0, 0, 0);
  }
}

@media all and (min-width: 900px) {
  .tab-toggler {
    display: none !important;
  }
}

@media all and (min-width: 1200px) {
  .swal-overlay--show-modal .swal-modal {
    border-radius: 2rem;
  }
}
@media all and (max-width: 992px) {
  .navbar .navbar-collapse.res-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 254, 250, 1);
    padding-top: 6rem;
    z-index: 20000;
    text-align: center;
  }
  .navbar-toggler,
  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    font-size: 0rem;
    padding: 0rem;
    z-index: 30000;
  }
  .navbar-nav .nav-link {
    margin: 0rem 0rem 1rem 0rem;
  }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner:after,
  .hamburger.is-active .hamburger-inner:before {
    background-color: #022453;
  }
  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background: rgb(10, 51, 107);
  }
}

.card__options {
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
}
.card__options .card__action{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: -30px;
    transition: all .3s ease-in-out;
    background-color: red;
    cursor: pointer;
    padding: .5rem;
    border-radius: .5rem;
    box-shadow: 3px 0px 5px rgba(0,0,0,0.03);
}
.card__options img{
  justify-content: end;
}

.card__options:hover .card__action{
    visibility: visible;
    opacity: 1;
    right: -15px;
}
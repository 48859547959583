.review-form {
  position: fixed;
  min-height: 100vh;
  min-width: 100vw;
  left: 0;
  top: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}

.review-header1 {
  color: #1b315b;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
}

.review-label {
  color: #1b315b;
  font-size: 18px;
  line-height: 23px;
}

.review-message {
  display: flex;
  flex-direction: row;
  background: #5d6c90;
  padding: 0.5rem;
  border-radius: 7px;
  color: #ffffff;
  align-items: center;
}

.times {
  cursor: pointer;
  color: #1b315b;
  font-size: 26px;
}

.check {
  color: #1b315b;
  font-size: 24px;
  padding-right: 1rem;
}

.rating i {
  cursor: pointer;
  font-size: 30px;
  margin-right: 15px;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.on {
  color: rgb(255, 217, 4);
}
.off {
  color: #cccccc;
}

@media screen and (max-width: 600px) {
  .rating i {
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 600px) {
  .rating i {
    cursor: pointer;
    font-size: 15px;
    margin-right: 5px !important;
  }
}

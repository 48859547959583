.round-blue-btn {
    width: 200px;
    padding:0rem 2rem;
    height: 70px;
    border: 1px solid transparent;
    border-radius: 3rem;
    color: white;
    cursor: pointer;
    font-size: 1.1rem;
    background-color: #092B5D;
}
.round-blue-btn.inverse {
    background-color: #357AFF;
}

.round-blue-btn:hover{
    opacity: 0.8;
}
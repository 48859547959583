.head{
    background-color:#1C0734;
    padding: 30PX;
    color: white;
}
.head h1{
    border-top: 3px solid white;
    border-bottom: 3px solid white;
    width: 30%;
}
.main{
    padding: 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.content p{
    
}
ul {
  padding-inline-start: 0;
}
ul.stepper {
  /* padding: 0 1.5rem;
  padding: 1.5rem; */
  /* margin: 1em -1.5rem; */
  overflow-x: hidden;
  overflow-y: auto;
  counter-reset: section;
}
.stepper-horizontal {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.stepper-horizontal > li:not(:last-of-type) {
  margin-bottom: 0 !important;
}
ul.stepper li {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.stepper-horizontal li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
ul.stepper li a {
  padding: 0.5rem;
  text-align: center;
}
.primary-color,
ul.stepper li.active a .circle,
ul.stepper li.completed a .circle {
  background-color: #4285f4 !important;
}
ul.stepper li a .circle {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.5rem;
  line-height: 1.7rem;
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.38);
  border-radius: 50%;
}
a {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
ul.stepper li.active a .label,
ul.stepper li.completed a .label {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}
ul.stepper li a .label {
  display: inline-block;
  color: rgba(0, 0, 0, 0.38);
}
.stepper-horizontal li a .label {
  margin-top: 0.63rem;
}
ul.stepper > li:not(:last-of-type) {
  margin-bottom: 0.625rem;
  -webkit-transition: margin-bottom 0.4s;
  -o-transition: margin-bottom 0.4s;
  transition: margin-bottom 0.4s;
}
.stepper-horizontal li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.stepper-horizontal li:not(:last-child):after {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 1px;
  margin: 0.5rem 0 0 0;
  content: "";
  background-color: #1b315b;
}
/* @media (max-width: 47.9375rem) {
  .stepper-horizontal li:not(:last-child):after {
    position: absolute;
    top: 3.75rem;
    left: 2.19rem;
    width: 1px;
    height: calc(100% - 40px);
    content: "";
  }
  .stepper-horizontal li {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .stepper-horizontal {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
} */


@media (min-width: 576px)
{
    body.vertical-layout.vertical-menu-modern.menu-flipped .main-menu
    {
        right: 0;
    }
}

@media (max-width: 576px)
{
    body.vertical-layout.vertical-menu-modern.menu-flipped .main-menu
    {
        right: 0;
    }
}

body.vertical-layout.vertical-menu-modern .navbar .navbar-brand
{
    white-space: nowrap;
}

body.vertical-layout.vertical-menu-modern .navbar .navbar-header .nav-toggle
{
    position: absolute;
    right: 0;
}

body.vertical-layout.vertical-menu-modern .main-menu
{
    -webkit-transition: .4s cubic-bezier(.25, .8, .25, 1);
       -moz-transition: .4s cubic-bezier(.25, .8, .25, 1);
         -o-transition: .4s cubic-bezier(.25, .8, .25, 1);
            transition: .4s cubic-bezier(.25, .8, .25, 1);
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);

    -webkit-backface-visibility: hidden;
       -moz-backface-visibility: hidden;
            backface-visibility: hidden;
}
body.vertical-layout.vertical-menu-modern .main-menu li a span
{
    -webkit-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
       -moz-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
         -o-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
            animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .navbar .navbar-header
{
    float: left;

    width: 260px;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .navbar.navbar-brand-center .navbar-header
{
    float: left;

    width: auto;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .navbar .navbar-container
{
    margin-left: 260px;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .navbar .navbar-brand .brand-text
{
    -webkit-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
       -moz-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
         -o-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
            animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu
{
    width: 260px;
}
body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation .navigation-header .ft-minus
{
    display: none;
}
body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation > li > a > i
{
    float: left; 

    margin-right: 12px;
}
body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation > li > a > i:before
{
    font-size: 1.4rem;
}
body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation > li > a > span.badge
{
    position: absolute;
    right: 20px;
}
body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub > a:not(.mm-next):after
{
    font-family: 'LineAwesome';
    font-size: 1rem;

    position: absolute;
    top: 14px;
    right: 20px;

    display: inline-block;

    content: '\f112';
    transition: -webkit-transform .2s ease-in-out; 
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}
body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub .has-sub > a:not(.mm-next):after
{
    top: 8px;
}
body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.open > a:not(.mm-next):after
{
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}
body.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .main-menu-footer
{
    width: 260px;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .content,
body.vertical-layout.vertical-menu-modern.menu-expanded .footer
{
    margin-left: 260px;
}

body.vertical-layout.vertical-menu-modern.menu-expanded .navbar-brand-center .content,
body.vertical-layout.vertical-menu-modern.menu-expanded .navbar-brand-center .footer
{
    margin-left: 0;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header
{
    float: left;

    width: 60px;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header .modern-nav-toggle
{
    display: none;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header.expanded
{
    z-index: 1000; 

    width: 260px;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header.expanded .modern-nav-toggle
{
    display: block;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header.expanded .navbar-brand .brand-text
{
    -webkit-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
       -moz-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
         -o-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
            animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header .navbar-brand .brand-text
{
    -webkit-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
       -moz-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
         -o-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
            animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .navbar.navbar-brand-center .navbar-header
{
    float: left;

    width: auto;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-brand
{
    padding: 17px 0;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-container
{
    margin-left: 60px;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu
{
    width: 60px;

    -webkit-transition: 300ms ease all;
       -moz-transition: 300ms ease all;
         -o-transition: 300ms ease all;
            transition: 300ms ease all;
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); 

    -webkit-backface-visibility: hidden;
       -moz-backface-visibility: hidden;
            backface-visibility: hidden;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .main-menu-footer
{
    width: 60px;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .main-menu-footer,
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .main-menu-header .media-body .media-heading,
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .main-menu-header .media-body .text-muted,
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .main-menu-header .media-right
{
    display: none;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .main-menu-header .media-body
{
    opacity: 0;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .user-content
{
    padding: 20px 10px;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation
{
    overflow: visible;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li.navigation-header .ft-minus
{
    font-size: 1.2rem; 

    display: block;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li.navigation-header span
{
    display: none;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li > a
{
    text-overflow: inherit;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li > a > span
{
    -webkit-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
       -moz-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
         -o-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
            animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadeout;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li > a > span.badge
{
    float: none !important;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li > a i
{
    float: left; 

    margin-right: 12px;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li > a i:before
{
    font-size: 1.4rem;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .mTSWrapper
{
    overflow: visible;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded
{
    width: 260px;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation > li.navigation-header .ft-minus
{
    display: none;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation > li.navigation-header span
{
    display: block;
}
body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation > li > a > span
{
    -webkit-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
       -moz-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
         -o-animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
            animation: .3s cubic-bezier(.25, .8, .25, 1) 0s normal forwards 1 fadein;
}
div.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation > li > a > span.badge
{
    position: absolute;
    right: 20px;
}
div.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub > a:not(.mm-next):after
{
    font-family: 'LineAwesome';
    font-size: 1rem;

    position: absolute;
    top: 14px;
    right: 20px;

    display: inline-block;

    content: '\f112';
    transition: -webkit-transform .2s ease-in-out; 
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}
div.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub .has-sub > a:not(.mm-next):after
{
    top: 8px;
}
div.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.open > a:not(.mm-next):after
{
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}

div.vertical-layout.vertical-menu-modern.menu-collapsed .content,
div.vertical-layout.vertical-menu-modern.menu-collapsed .footer
{
    margin-left: 60px;
}

div.vertical-layout.vertical-menu-modern.menu-collapsed .navbar-brand-center .content,
div.vertical-layout.vertical-menu-modern.menu-collapsed .navbar-brand-center .footer
{
    margin-left: 0;
}

div.vertical-layout.vertical-menu-modern.menu-collapsed.menu-flipped .content
{
    margin: 0;
    margin-right: 60px;
}

@media (min-width: 576px)
{
    body.vertical-layout.vertical-menu-modern.menu-collapsed.menu-flipped .main-menu
    {
        right: 0;
    }
    body.vertical-layout.vertical-menu-modern.menu-collapsed.menu-flipped .main-menu span.menu-title
    {
        right: 0px;
    }
    body.vertical-layout.vertical-menu-modern.menu-collapsed.menu-flipped .main-menu ul.menu-content
    {
        right: 0px;
        left: inherit;
    }
}

body.vertical-layout.vertical-menu-modern.menu-collapsed.menu-flipped .navbar .navbar-header
{
    float: right;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed.menu-flipped .navbar .navbar-container
{
    margin: 0;
    margin-right: 60px;
}

body.vertical-layout.vertical-menu-modern.menu-collapsed.menu-flipped .footer
{
    margin: 0;
    margin-right: 60px;
}

[data-textdirection='rtl'] body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li > a
{
    padding: 14px 16px !important;
}

[data-textdirection='rtl'] body.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation > li.navigation-header .ft-minus
{
    padding: 12px 0;
}

@media (min-width: 992px)
{
    body.vertical-layout.vertical-menu-modern.menu-flipped .content
    {
        margin: 0;
        margin-right: 260px;
    }
    body.vertical-layout.vertical-menu-modern.menu-flipped .navbar .navbar-header
    {
        float: right;
    }
    body.vertical-layout.vertical-menu-modern.menu-flipped .navbar .navbar-container
    {
        margin: 0;
        margin-right: 260px;
    }
    body.vertical-layout.vertical-menu-modern.menu-flipped .footer
    {
        margin: 0;
        margin-right: 260px;
    }
}

@media (max-width: 991.98px)
{
    body.vertical-layout.vertical-menu-modern .main-menu
    {
        width: 60px;
    }
    body.vertical-layout.vertical-menu-modern .main-menu .main-menu-footer
    {
        width: 260px;
    }
    body.vertical-layout.vertical-menu-modern .navbar .navbar-header
    {
        width: 60px;
    }
    body.vertical-layout.vertical-menu-modern .content,
    body.vertical-layout.vertical-menu-modern .footer
    {
        margin-left: 60px;
    }
}

@media (max-width: 767.98px)
{
    body.vertical-layout.vertical-menu-modern.menu-expanded .navbar .navbar-container
    {
        margin-left: 0 !important;
    }
    body.vertical-layout.vertical-menu-modern.menu-expanded .content,
    body.vertical-layout.vertical-menu-modern.menu-expanded .footer
    {
        margin-left: 0 !important;
    }
    html.loading .main-menu,
    html.loading .vertical-overlay-menu.menu-hide .main-menu,
    .vertical-overlay-menu.menu-hide html.loading .main-menu
    {
        -webkit-transform: translate3d(0px, 0, 0);
           -moz-transform: translate3d(0px, 0, 0);
                transform: translate3d(0px, 0, 0); 

        opacity: 0;
    }
}

@-webkit-keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@-moz-keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@-o-keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@keyframes fadein
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: 1;
    }
}

@-webkit-keyframes fadeout
{
    from
    {
        opacity: 1;
    }
    to
    {
        opacity: 0;
    }
}

@-moz-keyframes fadeout
{
    from
    {
        opacity: 1;
    }
    to
    {
        opacity: 0;
    }
}

@-o-keyframes fadeout
{
    from
    {
        opacity: 1;
    }
    to
    {
        opacity: 0;
    }
}

@keyframes fadeout
{
    from
    {
        opacity: 1;
    }
    to
    {
        opacity: 0;
    }
}


.vertical-overlay-menu .content
{
    margin-left: 0;
}

.vertical-overlay-menu .navbar .navbar-header
{
    float: left;

    width: 260px;
}

.vertical-overlay-menu .navbar.navbar-brand-center .navbar-container
{
    margin-left: 0;
}

.vertical-overlay-menu .navbar.navbar-brand-center .navbar-header
{
    float: left;

    width: auto;
}

.vertical-overlay-menu .main-menu,
.vertical-overlay-menu.menu-hide .main-menu
{
    left: -260px; 

    width: 260px;

    -webkit-transition: width .25s,opacity .25s,-webkit-transform .25s;
       -moz-transition: width .25s,opacity .25s,transform .25s,-moz-transform .25s;
         -o-transition: width .25s,opacity .25s,-o-transform .25s;
            transition: width .25s,opacity .25s,-webkit-transform .25s;
            transition: width .25s,opacity .25s,transform .25s;
            transition: width .25s,opacity .25s,transform .25s,-webkit-transform .25s,-moz-transform .25s,-o-transform .25s;
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);

    opacity: 0;
}
.vertical-overlay-menu .main-menu .navigation .navigation-header .ft-minus
{
    display: none;
}
.vertical-overlay-menu .main-menu .navigation > li > a > i
{
    font-size: 1.4rem;

    float: left; 

    margin-right: 12px;
}
.vertical-overlay-menu .main-menu .navigation > li > a > i:before
{
    -webkit-transition: 200ms ease all;
       -moz-transition: 200ms ease all;
         -o-transition: 200ms ease all;
            transition: 200ms ease all;
}
.vertical-overlay-menu .main-menu .navigation li.has-sub > a:not(.mm-next):after
{
    font-family: 'LineAwesome';
    font-size: 1rem;

    position: absolute;
    top: 14px;
    right: 20px;

    display: inline-block;

    content: '\f112';
    transition: -webkit-transform .2s ease-in-out; 
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);
}
.vertical-overlay-menu .main-menu .navigation li.has-sub .has-sub > a:not(.mm-next):after
{
    top: 8px;
}
.vertical-overlay-menu .main-menu .navigation li.open > a:not(.mm-next):after
{
    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}
.vertical-overlay-menu .main-menu .main-menu-footer
{
    bottom: 55px;
}
.vertical-overlay-menu .main-menu .main-menu-footer
{
    width: 260px;
}

.vertical-overlay-menu.menu-open .main-menu
{
    -webkit-transition: width .25s,opacity .25s,-webkit-transform .25s;
       -moz-transition: width .25s,opacity .25s,transform .25s,-moz-transform .25s;
         -o-transition: width .25s,opacity .25s,-o-transform .25s;
            transition: width .25s,opacity .25s,-webkit-transform .25s;
            transition: width .25s,opacity .25s,transform .25s;
            transition: width .25s,opacity .25s,transform .25s,-webkit-transform .25s,-moz-transform .25s,-o-transform .25s; 
    -webkit-transform: translate3d(260px, 0, 0);
       -moz-transform: translate3d(260px, 0, 0);
            transform: translate3d(260px, 0, 0);

    opacity: 1;
}

.vertical-overlay-menu.menu-flipped .main-menu
{
    right: -260px;
    left: inherit;
}

.vertical-overlay-menu.menu-flipped .navbar .navbar-container
{
    margin: 0;
    margin-right: 260px;
}

.vertical-overlay-menu.menu-flipped .navbar .navbar-header
{
    float: right;
}

.vertical-overlay-menu.menu-flipped.menu-open .main-menu
{
    -webkit-transform: translate3d(-260px, 0, 0);
       -moz-transform: translate3d(-260px, 0, 0);
            transform: translate3d(-260px, 0, 0);
}

@media (max-width: 991.98px)
{
    .vertical-overlay-menu .main-menu .main-menu-footer
    {
        bottom: 0;
    }
}

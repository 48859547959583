/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root
{
    --blue: #1e9ff2;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #ff4961;
    --orange: #ff9149;
    --yellow: #ffc107;
    --green: #28d094;
    --teal: #20c997;
    --cyan: #666ee8;
    --white: #fff;
    --gray: #6b6f82;
    --gray-dark: #464855;
    --primary: #666ee8;
    --secondary: #6b6f82;
    --success: #28d094;
    --info: #1e9ff2;
    --warning: #ff9149;
    --danger: #ff4961;
    --light: #babfc7;
    --dark: #464855;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    --font-family-monospace: 'Quicksand', Georgia, 'Times New Roman', Times, serif;
}

*,
*::before,
*::after
{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

html
{
    font-family: sans-serif;
    line-height: 1.15;

    -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport
{
    width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section
{
    display: block;
}

body
{
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;

    margin: 0;

    text-align: left;

    /*color: #6b6f82;*/
    /*background-color: #f9fafd;*/
}

[tabindex='-1']:focus
{
    outline: 0 !important;
}

hr
{
    overflow: visible; 

    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
    height: 0;
}

h1,
h2,
h3,
h4,
h5,
h6
{
    margin-top: 0;
    margin-bottom: .5rem;
}

p
{
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title]
{
    cursor: help;
            text-decoration: underline;
            text-decoration: underline dotted;

    border-bottom: 0; 

    -webkit-text-decoration: underline dotted;
       -moz-text-decoration: underline dotted;
}

address
{
    font-style: normal;
    line-height: inherit; 

    margin-bottom: 1rem;
}

ol,
ul,
dl
{
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol
{
    margin-bottom: 0;
}

dt
{
    font-weight: 700;
}

dd
{
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote
{
    margin: 0 0 1rem;
}

dfn
{
    font-style: italic;
}

b,
strong
{
    font-weight: bolder;
}

small
{
    font-size: 80%;
}

sub,
sup
{
    font-size: 75%;
    line-height: 0;

    position: relative;

    vertical-align: baseline;
}

sub
{
    bottom: -.25em;
}

sup
{
    top: -.5em;
}

a
{
    text-decoration: none;

    color: #5059e5;
    background-color: transparent;

    -webkit-text-decoration-skip: objects;
}
a:hover
{
    text-decoration: none; 

    color: #3a44e1;
}

pre,
code,
kbd,
samp
{
    font-family: 'Quicksand', Georgia, 'Times New Roman', Times, serif;
    font-size: 1em;
}

pre
{
    overflow: auto;

    margin-top: 0;
    margin-bottom: 1rem;

    -ms-overflow-style: scrollbar;
}

figure
{
    margin: 0 0 1rem;
}

img
{
    vertical-align: middle;

    border-style: none;
}

svg
{
    overflow: hidden;

    vertical-align: middle;
}

table
{
    border-collapse: collapse;
}

caption
{
    padding-top: .75rem;
    padding-bottom: .75rem;

    caption-side: bottom; 

    text-align: left;

    color: #6b6f82;
}

th
{
    text-align: inherit;
}

label
{
    display: inline-block;

    margin-bottom: .5rem;
}

button
{
    border-radius: 0;
}

button:focus
{
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea
{
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; 

    margin: 0;
}

button,
input
{
    overflow: visible;
}

button,
select
{
    text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit']
{
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner
{
    padding: 0;

    border-style: none;
}

input[type='radio'],
input[type='checkbox']
{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month']
{
    -webkit-appearance: listbox;
}

textarea
{
    overflow: auto;

    resize: vertical;
}

fieldset
{
    min-width: 0;
    margin: 0;
    padding: 0;

    border: 0;
}

legend
{
    font-size: 1.5rem;
    line-height: inherit;

    display: block;

    width: 100%;
    max-width: 100%;
    margin-bottom: .5rem;
    padding: 0;

    white-space: normal; 

    color: inherit;
}

progress
{
    vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button
{
    height: auto;
}

[type='search']
{
    outline-offset: -2px;

    -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration
{
    -webkit-appearance: none;
}

::-webkit-file-upload-button
{
    font: inherit;

    -webkit-appearance: button;
}

output
{
    display: inline-block;
}

summary
{
    display: list-item;

    cursor: pointer;
}

template
{
    display: none;
}

[hidden]
{
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6
{
    font-family: 'Quicksand', Georgia, 'Times New Roman', Times, serif;
    font-weight: 400;
    line-height: 1.2;

    margin-bottom: .5rem;
    /*color: #464855;*/
}

h1,
.h1
{
    font-size: 2rem;
}

h2,
.h2
{
    font-size: 1.74rem;
}

h3,
.h3
{
    font-size: 1.51rem;
}

h4,
.h4
{
    font-size: 1.32rem;
}

h5,
.h5
{
    font-size: 1.14rem;
}

h6,
.h6
{
    font-size: 1rem;
}

.lead
{
    font-size: 1.25rem;
    font-weight: 400;
}

.display-1
{
    font-size: 6rem;
    font-weight: 400;
    line-height: 1.2;
}

.display-2
{
    font-size: 5.5rem;
    font-weight: 400;
    line-height: 1.2;
}

.display-3
{
    font-size: 4.5rem;
    font-weight: 400;
    line-height: 1.2;
}

.display-4
{
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 1.2;
}

hr
{
    margin-top: 1rem;
    margin-bottom: 1rem;

    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

small,
.small
{
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark
{
    padding: .2em;

    background-color: #fcf8e3;
}

.list-unstyled
{
    padding-left: 0;

    list-style: none;
}

.list-inline
{
    padding-left: 0;

    list-style: none;
}

.list-inline-item
{
    display: inline-block;
}
.list-inline-item:not(:last-child)
{
    margin-right: .5rem;
}

.initialism
{
    font-size: 90%;

    text-transform: uppercase;
}

.blockquote
{
    font-size: 1.25rem; 

    margin-bottom: 1rem;
}

.blockquote-footer
{
    font-size: 80%;

    display: block;

    color: #6b6f82;
}
.blockquote-footer::before
{
    content: '\2014 \00A0';
}

.img-fluid
{
    max-width: 100%;
    height: auto;
}

.img-thumbnail
{
    max-width: 100%;
    height: auto; 
    padding: .25rem;

    border: 1px solid #626e82;
    border-radius: .25rem;
    background-color: #f9fafd;
}

.figure
{
    display: inline-block;
}

.figure-img
{
    line-height: 1; 

    margin-bottom: .5rem;
}

.figure-caption
{
    font-size: 90%;

    color: #6b6f82;
}

code
{
    font-size: 87.5%;

    word-break: break-word; 

    color: #f74b5c;
}
a > code
{
    color: inherit;
}

kbd
{
    font-size: 87.5%;

    padding: .2rem .4rem;

    color: #fff;
    border-radius: .21rem; 
    background-color: #f2f3f5;
}
kbd kbd
{
    font-size: 100%;
    font-weight: 700; 

    padding: 0;
}

pre
{
    font-size: 87.5%;

    display: block;

    color: #6b6f82;
}
pre code
{
    font-size: inherit;

    word-break: normal; 

    color: inherit;
}

.pre-scrollable
{
    overflow-y: scroll; 

    max-height: 340px;
}

.container
{
    width: 100%;
    margin-right: auto;
    margin-left: auto; 
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 576px)
{
    .container
    {
        max-width: 540px;
    }
}
@media (min-width: 768px)
{
    .container
    {
        max-width: 720px;
    }
}
@media (min-width: 992px)
{
    .container
    {
        max-width: 960px;
    }
}
@media (min-width: 1200px)
{
    .container
    {
        max-width: 1140px;
    }
}

.container-fluid
{
    width: 100%;
    margin-right: auto;
    margin-left: auto; 
    padding-right: 15px;
    padding-left: 15px;
}

.row
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-right: -15px;
    margin-left: -15px; 

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.no-gutters
{
    margin-right: 0;
    margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-']
{
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto
{
    position: relative;

    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col
{
    max-width: 100%; 

    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
    -ms-flex-positive: 1;
            flex-grow: 1;
}

.col-auto
{
    width: auto;
    max-width: none; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}

.col-1
{
    max-width: 8.33333%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
}

.col-2
{
    max-width: 16.66667%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
}

.col-3
{
    max-width: 25%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
}

.col-4
{
    max-width: 33.33333%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
}

.col-5
{
    max-width: 41.66667%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
}

.col-6
{
    max-width: 50%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
}

.col-7
{
    max-width: 58.33333%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
}

.col-8
{
    max-width: 66.66667%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
}

.col-9
{
    max-width: 75%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
}

.col-10
{
    max-width: 83.33333%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
}

.col-11
{
    max-width: 91.66667%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
}

.col-12
{
    max-width: 100%; 

    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
       -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
}

.order-first
{
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
       -moz-box-ordinal-group: 0;
    -ms-flex-order: -1;
            order: -1;
}

.order-last
{
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
       -moz-box-ordinal-group: 14;
    -ms-flex-order: 13;
            order: 13;
}

.order-0
{
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
       -moz-box-ordinal-group: 1;
    -ms-flex-order: 0;
            order: 0;
}

.order-1
{
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
       -moz-box-ordinal-group: 2;
    -ms-flex-order: 1;
            order: 1;
}

.order-2
{
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
       -moz-box-ordinal-group: 3;
    -ms-flex-order: 2;
            order: 2;
}

.order-3
{
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
       -moz-box-ordinal-group: 4;
    -ms-flex-order: 3;
            order: 3;
}

.order-4
{
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
       -moz-box-ordinal-group: 5;
    -ms-flex-order: 4;
            order: 4;
}

.order-5
{
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
       -moz-box-ordinal-group: 6;
    -ms-flex-order: 5;
            order: 5;
}

.order-6
{
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
       -moz-box-ordinal-group: 7;
    -ms-flex-order: 6;
            order: 6;
}

.order-7
{
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
       -moz-box-ordinal-group: 8;
    -ms-flex-order: 7;
            order: 7;
}

.order-8
{
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
       -moz-box-ordinal-group: 9;
    -ms-flex-order: 8;
            order: 8;
}

.order-9
{
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
       -moz-box-ordinal-group: 10;
    -ms-flex-order: 9;
            order: 9;
}

.order-10
{
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
       -moz-box-ordinal-group: 11;
    -ms-flex-order: 10;
            order: 10;
}

.order-11
{
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
       -moz-box-ordinal-group: 12;
    -ms-flex-order: 11;
            order: 11;
}

.order-12
{
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
       -moz-box-ordinal-group: 13;
    -ms-flex-order: 12;
            order: 12;
}

.offset-1
{
    margin-left: 8.33333%;
}

.offset-2
{
    margin-left: 16.66667%;
}

.offset-3
{
    margin-left: 25%;
}

.offset-4
{
    margin-left: 33.33333%;
}

.offset-5
{
    margin-left: 41.66667%;
}

.offset-6
{
    margin-left: 50%;
}

.offset-7
{
    margin-left: 58.33333%;
}

.offset-8
{
    margin-left: 66.66667%;
}

.offset-9
{
    margin-left: 75%;
}

.offset-10
{
    margin-left: 83.33333%;
}

.offset-11
{
    margin-left: 91.66667%;
}

@media (min-width: 576px)
{
    .col-sm
    {
        max-width: 100%; 

        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
                flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
           -moz-box-flex: 1;
        -ms-flex-positive: 1;
                flex-grow: 1;
    }
    .col-sm-auto
    {
        width: auto;
        max-width: none; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
           -moz-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
    }
    .col-sm-1
    {
        max-width: 8.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 8.33333%;
                flex: 0 0 8.33333%;
    }
    .col-sm-2
    {
        max-width: 16.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 16.66667%;
                flex: 0 0 16.66667%;
    }
    .col-sm-3
    {
        max-width: 25%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
    }
    .col-sm-4
    {
        max-width: 33.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
                flex: 0 0 33.33333%;
    }
    .col-sm-5
    {
        max-width: 41.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 41.66667%;
                flex: 0 0 41.66667%;
    }
    .col-sm-6
    {
        max-width: 50%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
    }
    .col-sm-7
    {
        max-width: 58.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 58.33333%;
                flex: 0 0 58.33333%;
    }
    .col-sm-8
    {
        max-width: 66.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 66.66667%;
                flex: 0 0 66.66667%;
    }
    .col-sm-9
    {
        max-width: 75%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 75%;
                flex: 0 0 75%;
    }
    .col-sm-10
    {
        max-width: 83.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 83.33333%;
                flex: 0 0 83.33333%;
    }
    .col-sm-11
    {
        max-width: 91.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 91.66667%;
                flex: 0 0 91.66667%;
    }
    .col-sm-12
    {
        max-width: 100%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
    }
    .order-sm-first
    {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
           -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
                order: -1;
    }
    .order-sm-last
    {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
           -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
                order: 13;
    }
    .order-sm-0
    {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
           -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
                order: 0;
    }
    .order-sm-1
    {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
           -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
                order: 1;
    }
    .order-sm-2
    {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
           -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
                order: 2;
    }
    .order-sm-3
    {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
           -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
                order: 3;
    }
    .order-sm-4
    {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
           -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
                order: 4;
    }
    .order-sm-5
    {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
           -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
                order: 5;
    }
    .order-sm-6
    {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
           -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
                order: 6;
    }
    .order-sm-7
    {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
           -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
                order: 7;
    }
    .order-sm-8
    {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
           -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
                order: 8;
    }
    .order-sm-9
    {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
           -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
                order: 9;
    }
    .order-sm-10
    {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
           -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
                order: 10;
    }
    .order-sm-11
    {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
           -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
                order: 11;
    }
    .order-sm-12
    {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
           -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
                order: 12;
    }
    .offset-sm-0
    {
        margin-left: 0;
    }
    .offset-sm-1
    {
        margin-left: 8.33333%;
    }
    .offset-sm-2
    {
        margin-left: 16.66667%;
    }
    .offset-sm-3
    {
        margin-left: 25%;
    }
    .offset-sm-4
    {
        margin-left: 33.33333%;
    }
    .offset-sm-5
    {
        margin-left: 41.66667%;
    }
    .offset-sm-6
    {
        margin-left: 50%;
    }
    .offset-sm-7
    {
        margin-left: 58.33333%;
    }
    .offset-sm-8
    {
        margin-left: 66.66667%;
    }
    .offset-sm-9
    {
        margin-left: 75%;
    }
    .offset-sm-10
    {
        margin-left: 83.33333%;
    }
    .offset-sm-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px)
{
    .col-md
    {
        max-width: 100%; 

        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
                flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
           -moz-box-flex: 1;
        -ms-flex-positive: 1;
                flex-grow: 1;
    }
    .col-md-auto
    {
        width: auto;
        max-width: none; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
           -moz-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
    }
    .col-md-1
    {
        max-width: 8.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 8.33333%;
                flex: 0 0 8.33333%;
    }
    .col-md-2
    {
        max-width: 16.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 16.66667%;
                flex: 0 0 16.66667%;
    }
    .col-md-3
    {
        max-width: 25%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
    }
    .col-md-4
    {
        max-width: 33.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
                flex: 0 0 33.33333%;
    }
    .col-md-5
    {
        max-width: 41.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 41.66667%;
                flex: 0 0 41.66667%;
    }
    .col-md-6
    {
        max-width: 50%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
    }
    .col-md-7
    {
        max-width: 58.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 58.33333%;
                flex: 0 0 58.33333%;
    }
    .col-md-8
    {
        max-width: 66.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 66.66667%;
                flex: 0 0 66.66667%;
    }
    .col-md-9
    {
        max-width: 75%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 75%;
                flex: 0 0 75%;
    }
    .col-md-10
    {
        max-width: 83.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 83.33333%;
                flex: 0 0 83.33333%;
    }
    .col-md-11
    {
        max-width: 91.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 91.66667%;
                flex: 0 0 91.66667%;
    }
    .col-md-12
    {
        max-width: 100%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
    }
    .order-md-first
    {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
           -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
                order: -1;
    }
    .order-md-last
    {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
           -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
                order: 13;
    }
    .order-md-0
    {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
           -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
                order: 0;
    }
    .order-md-1
    {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
           -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
                order: 1;
    }
    .order-md-2
    {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
           -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
                order: 2;
    }
    .order-md-3
    {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
           -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
                order: 3;
    }
    .order-md-4
    {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
           -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
                order: 4;
    }
    .order-md-5
    {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
           -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
                order: 5;
    }
    .order-md-6
    {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
           -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
                order: 6;
    }
    .order-md-7
    {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
           -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
                order: 7;
    }
    .order-md-8
    {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
           -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
                order: 8;
    }
    .order-md-9
    {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
           -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
                order: 9;
    }
    .order-md-10
    {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
           -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
                order: 10;
    }
    .order-md-11
    {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
           -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
                order: 11;
    }
    .order-md-12
    {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
           -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
                order: 12;
    }
    .offset-md-0
    {
        margin-left: 0;
    }
    .offset-md-1
    {
        margin-left: 8.33333%;
    }
    .offset-md-2
    {
        margin-left: 16.66667%;
    }
    .offset-md-3
    {
        margin-left: 25%;
    }
    .offset-md-4
    {
        margin-left: 33.33333%;
    }
    .offset-md-5
    {
        margin-left: 41.66667%;
    }
    .offset-md-6
    {
        margin-left: 50%;
    }
    .offset-md-7
    {
        margin-left: 58.33333%;
    }
    .offset-md-8
    {
        margin-left: 66.66667%;
    }
    .offset-md-9
    {
        margin-left: 75%;
    }
    .offset-md-10
    {
        margin-left: 83.33333%;
    }
    .offset-md-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px)
{
    .col-lg
    {
        max-width: 100%; 

        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
                flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
           -moz-box-flex: 1;
        -ms-flex-positive: 1;
                flex-grow: 1;
    }
    .col-lg-auto
    {
        width: auto;
        max-width: none; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
           -moz-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
    }
    .col-lg-1
    {
        max-width: 8.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 8.33333%;
                flex: 0 0 8.33333%;
    }
    .col-lg-2
    {
        max-width: 16.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 16.66667%;
                flex: 0 0 16.66667%;
    }
    .col-lg-3
    {
        max-width: 25%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
    }
    .col-lg-4
    {
        max-width: 33.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
                flex: 0 0 33.33333%;
    }
    .col-lg-5
    {
        max-width: 41.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 41.66667%;
                flex: 0 0 41.66667%;
    }
    .col-lg-6
    {
        max-width: 50%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
    }
    .col-lg-7
    {
        max-width: 58.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 58.33333%;
                flex: 0 0 58.33333%;
    }
    .col-lg-8
    {
        max-width: 66.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 66.66667%;
                flex: 0 0 66.66667%;
    }
    .col-lg-9
    {
        max-width: 75%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 75%;
                flex: 0 0 75%;
    }
    .col-lg-10
    {
        max-width: 83.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 83.33333%;
                flex: 0 0 83.33333%;
    }
    .col-lg-11
    {
        max-width: 91.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 91.66667%;
                flex: 0 0 91.66667%;
    }
    .col-lg-12
    {
        max-width: 100%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
    }
    .order-lg-first
    {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
           -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
                order: -1;
    }
    .order-lg-last
    {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
           -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
                order: 13;
    }
    .order-lg-0
    {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
           -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
                order: 0;
    }
    .order-lg-1
    {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
           -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
                order: 1;
    }
    .order-lg-2
    {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
           -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
                order: 2;
    }
    .order-lg-3
    {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
           -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
                order: 3;
    }
    .order-lg-4
    {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
           -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
                order: 4;
    }
    .order-lg-5
    {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
           -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
                order: 5;
    }
    .order-lg-6
    {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
           -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
                order: 6;
    }
    .order-lg-7
    {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
           -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
                order: 7;
    }
    .order-lg-8
    {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
           -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
                order: 8;
    }
    .order-lg-9
    {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
           -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
                order: 9;
    }
    .order-lg-10
    {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
           -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
                order: 10;
    }
    .order-lg-11
    {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
           -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
                order: 11;
    }
    .order-lg-12
    {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
           -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
                order: 12;
    }
    .offset-lg-0
    {
        margin-left: 0;
    }
    .offset-lg-1
    {
        margin-left: 8.33333%;
    }
    .offset-lg-2
    {
        margin-left: 16.66667%;
    }
    .offset-lg-3
    {
        margin-left: 25%;
    }
    .offset-lg-4
    {
        margin-left: 33.33333%;
    }
    .offset-lg-5
    {
        margin-left: 41.66667%;
    }
    .offset-lg-6
    {
        margin-left: 50%;
    }
    .offset-lg-7
    {
        margin-left: 58.33333%;
    }
    .offset-lg-8
    {
        margin-left: 66.66667%;
    }
    .offset-lg-9
    {
        margin-left: 75%;
    }
    .offset-lg-10
    {
        margin-left: 83.33333%;
    }
    .offset-lg-11
    {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px)
{
    .col-xl
    {
        max-width: 100%; 

        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
                flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
           -moz-box-flex: 1;
        -ms-flex-positive: 1;
                flex-grow: 1;
    }
    .col-xl-auto
    {
        width: auto;
        max-width: none; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
           -moz-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
    }
    .col-xl-1
    {
        max-width: 8.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 8.33333%;
                flex: 0 0 8.33333%;
    }
    .col-xl-2
    {
        max-width: 16.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 16.66667%;
                flex: 0 0 16.66667%;
    }
    .col-xl-3
    {
        max-width: 25%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 25%;
                flex: 0 0 25%;
    }
    .col-xl-4
    {
        max-width: 33.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
                flex: 0 0 33.33333%;
    }
    .col-xl-5
    {
        max-width: 41.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 41.66667%;
                flex: 0 0 41.66667%;
    }
    .col-xl-6
    {
        max-width: 50%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
    }
    .col-xl-7
    {
        max-width: 58.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 58.33333%;
                flex: 0 0 58.33333%;
    }
    .col-xl-8
    {
        max-width: 66.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 66.66667%;
                flex: 0 0 66.66667%;
    }
    .col-xl-9
    {
        max-width: 75%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 75%;
                flex: 0 0 75%;
    }
    .col-xl-10
    {
        max-width: 83.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 83.33333%;
                flex: 0 0 83.33333%;
    }
    .col-xl-11
    {
        max-width: 91.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 91.66667%;
                flex: 0 0 91.66667%;
    }
    .col-xl-12
    {
        max-width: 100%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
    }
    .order-xl-first
    {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
           -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
                order: -1;
    }
    .order-xl-last
    {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
           -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
                order: 13;
    }
    .order-xl-0
    {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
           -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
                order: 0;
    }
    .order-xl-1
    {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
           -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
                order: 1;
    }
    .order-xl-2
    {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
           -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
                order: 2;
    }
    .order-xl-3
    {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
           -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
                order: 3;
    }
    .order-xl-4
    {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
           -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
                order: 4;
    }
    .order-xl-5
    {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
           -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
                order: 5;
    }
    .order-xl-6
    {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
           -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
                order: 6;
    }
    .order-xl-7
    {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
           -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
                order: 7;
    }
    .order-xl-8
    {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
           -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
                order: 8;
    }
    .order-xl-9
    {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
           -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
                order: 9;
    }
    .order-xl-10
    {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
           -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
                order: 10;
    }
    .order-xl-11
    {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
           -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
                order: 11;
    }
    .order-xl-12
    {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
           -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
                order: 12;
    }
    .offset-xl-0
    {
        margin-left: 0;
    }
    .offset-xl-1
    {
        margin-left: 8.33333%;
    }
    .offset-xl-2
    {
        margin-left: 16.66667%;
    }
    .offset-xl-3
    {
        margin-left: 25%;
    }
    .offset-xl-4
    {
        margin-left: 33.33333%;
    }
    .offset-xl-5
    {
        margin-left: 41.66667%;
    }
    .offset-xl-6
    {
        margin-left: 50%;
    }
    .offset-xl-7
    {
        margin-left: 58.33333%;
    }
    .offset-xl-8
    {
        margin-left: 66.66667%;
    }
    .offset-xl-9
    {
        margin-left: 75%;
    }
    .offset-xl-10
    {
        margin-left: 83.33333%;
    }
    .offset-xl-11
    {
        margin-left: 91.66667%;
    }
}

.table
{
    width: 100%;
    margin-bottom: 1rem;

    background-color: transparent;
}
.table th,
.table td
{
    padding: .75rem;

    vertical-align: top;

    border-top: 1px solid #626e82;
}
.table thead th
{
    vertical-align: bottom;

    border-bottom: 2px solid #626e82;
}
.table tbody + tbody
{
    border-top: 2px solid #626e82;
}
.table .table
{
    background-color: #f9fafd;
}

.table-sm th,
.table-sm td
{
    padding: .3rem;
}

.table-bordered
{
    border: 1px solid #626e82;
}
.table-bordered th,
.table-bordered td
{
    border: 1px solid #626e82;
}
.table-bordered thead th,
.table-bordered thead td
{
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody
{
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd)
{
    background-color: rgba(245, 247, 250, .5);
}

.table-hover tbody tr:hover
{
    background-color: rgba(245, 247, 250, .5);
}

.table-primary,
.table-primary > th,
.table-primary > td
{
    background-color: #d4d6f9;
}

.table-hover .table-primary:hover
{
    background-color: #bec1f6;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th
{
    background-color: #bec1f6;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td
{
    background-color: #d6d7dc;
}

.table-hover .table-secondary:hover
{
    background-color: #c8cad0;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th
{
    background-color: #c8cad0;
}

.table-success,
.table-success > th,
.table-success > td
{
    background-color: #c3f2e1;
}

.table-hover .table-success:hover
{
    background-color: #aeedd7;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th
{
    background-color: #aeedd7;
}

.table-info,
.table-info > th,
.table-info > td
{
    background-color: #c0e4fb;
}

.table-hover .table-info:hover
{
    background-color: #a8daf9;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th
{
    background-color: #a8daf9;
}

.table-warning,
.table-warning > th,
.table-warning > td
{
    background-color: #ffe0cc;
}

.table-hover .table-warning:hover
{
    background-color: #ffd1b3;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th
{
    background-color: #ffd1b3;
}

.table-danger,
.table-danger > th,
.table-danger > td
{
    background-color: #ffccd3;
}

.table-hover .table-danger:hover
{
    background-color: #ffb3bd;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th
{
    background-color: #ffb3bd;
}

.table-light,
.table-light > th,
.table-light > td
{
    background-color: #ecedef;
}

.table-hover .table-light:hover
{
    background-color: #dee0e3;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th
{
    background-color: #dee0e3;
}

.table-dark,
.table-dark > th,
.table-dark > td
{
    background-color: #cbcccf;
}

.table-hover .table-dark:hover
{
    background-color: #bebfc3;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th
{
    background-color: #bebfc3;
}

.table-active,
.table-active > th,
.table-active > td
{
    background-color: rgba(245, 247, 250, .5);
}

.table-hover .table-active:hover
{
    background-color: rgba(228, 233, 242, .5);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th
{
    background-color: rgba(228, 233, 242, .5);
}

.table .thead-dark th
{
    color: #f9fafd;
    border-color: #3c4244; 
    background-color: #2a2e30;
}

.table .thead-light th
{
    color: #4e5154;
    border-color: #626e82; 
    background-color: #98a4b8;
}

.table-dark
{
    color: #f9fafd;
    background-color: #2a2e30;
}
.table-dark th,
.table-dark td,
.table-dark thead th
{
    border-color: #3c4244;
}
.table-dark.table-bordered
{
    border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd)
{
    background-color: rgba(255, 255, 255, .05);
}
.table-dark.table-hover tbody tr:hover
{
    background-color: rgba(255, 255, 255, .075);
}

@media (max-width: 575.98px)
{
    .table-responsive-sm
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-sm > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 767.98px)
{
    .table-responsive-md
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-md > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 991.98px)
{
    .table-responsive-lg
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-lg > .table-bordered
    {
        border: 0;
    }
}

@media (max-width: 1199.98px)
{
    .table-responsive-xl
    {
        display: block;
        overflow-x: auto;

        width: 100%;

        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-xl > .table-bordered
    {
        border: 0;
    }
}

.table-responsive
{
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered
{
    border: 0;
}

.form-control
{
    font-size: 1rem;
    line-height: 1.25;

    display: block;

    width: 100%;
    height: -webkit-calc(2.75rem + 2px);
    height:    -moz-calc(2.75rem + 2px);
    height:         calc(2.75rem + 2px);
    padding: .75rem 1rem;

    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
       -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
         -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out; 

    color: #4e5154;
    border: 1px solid #babfc7;
    border-radius: .25rem;
    background-color: #fff;
    background-clip: padding-box;
}
@media screen and (prefers-reduced-motion: reduce)
{
    .form-control
    {
        -webkit-transition: none;
           -moz-transition: none;
             -o-transition: none;
                transition: none;
    }
}
.form-control::-ms-expand
{
    border: 0; 
    background-color: transparent;
}
.form-control:focus
{
    color: #4e5154;
    border-color: #1e9ff2;
    outline: 0;
    background-color: #fff;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.form-control::-webkit-input-placeholder
{
    opacity: 1; 
    color: #babfc7;
}
.form-control:-moz-placeholder
{
    opacity: 1; 
    color: #babfc7;
}
.form-control::-moz-placeholder
{
    opacity: 1; 
    color: #babfc7;
}
.form-control:-ms-input-placeholder
{
    opacity: 1; 
    color: #babfc7;
}
.form-control::placeholder
{
    opacity: 1; 
    color: #babfc7;
}
.form-control:disabled,
.form-control[readonly]
{
    opacity: 1; 
    background-color: #98a4b8;
}

select.form-control:focus::-ms-value
{
    color: #4e5154;
    background-color: #fff;
}

.form-control-file,
.form-control-range
{
    display: block;

    width: 100%;
}

.col-form-label
{
    font-size: inherit;
    line-height: 1.25; 

    margin-bottom: 0;
    padding-top: -webkit-calc(.75rem + 1px);
    padding-top:    -moz-calc(.75rem + 1px);
    padding-top:         calc(.75rem + 1px);
    padding-bottom: -webkit-calc(.75rem + 1px);
    padding-bottom:    -moz-calc(.75rem + 1px);
    padding-bottom:         calc(.75rem + 1px);
}

.col-form-label-lg
{
    font-size: 1.25rem;
    line-height: 1.25; 

    padding-top: -webkit-calc(1rem + 1px);
    padding-top:    -moz-calc(1rem + 1px);
    padding-top:         calc(1rem + 1px);
    padding-bottom: -webkit-calc(1rem + 1px);
    padding-bottom:    -moz-calc(1rem + 1px);
    padding-bottom:         calc(1rem + 1px);
}

.col-form-label-sm
{
    font-size: .875rem;
    line-height: 1; 

    padding-top: -webkit-calc(.5rem + 1px);
    padding-top:    -moz-calc(.5rem + 1px);
    padding-top:         calc(.5rem + 1px);
    padding-bottom: -webkit-calc(.5rem + 1px);
    padding-bottom:    -moz-calc(.5rem + 1px);
    padding-bottom:         calc(.5rem + 1px);
}

.form-control-plaintext
{
    line-height: 1.25;

    display: block;

    width: 100%;
    margin-bottom: 0;
    padding-top: .75rem;
    padding-bottom: .75rem;

    color: #6b6f82;
    border: solid transparent;
    border-width: 1px 0; 
    background-color: transparent;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg
{
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm
{
    font-size: .875rem;
    line-height: 1;

    height: -webkit-calc(1.875rem + 2px + 10);
    height:    -moz-calc(1.875rem + 2px + 10);
    height:         calc(1.875rem + 2px + 10);
    padding: .5rem .75rem;

    border-radius: .21rem;
}

.form-control-lg
{
    font-size: 1.25rem;
    line-height: 1.25;

    height: -webkit-calc(3.5625rem + 2px);
    height:    -moz-calc(3.5625rem + 2px);
    height:         calc(3.5625rem + 2px);
    padding: 1rem 1.25rem;

    border-radius: .35rem;
}

select.form-control[size],
select.form-control[multiple]
{
    height: auto;
}

textarea.form-control
{
    height: auto;
}

.form-group
{
    margin-bottom: 1rem;
}

.form-text
{
    display: block;

    margin-top: .25rem;
}

.form-row
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-right: -5px;
    margin-left: -5px; 

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.form-row > .col,
.form-row > [class*='col-']
{
    padding-right: 5px;
    padding-left: 5px;
}

.form-check
{
    position: relative;

    display: block;

    padding-left: 1.25rem;
}

.form-check-input
{
    position: absolute;

    margin-top: .3rem;
    margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label
{
    color: #6b6f82;
}

.form-check-label
{
    margin-bottom: 0;
}

.form-check-inline
{
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display:    -moz-inline-box;
    display: -ms-inline-flexbox;
    display:         inline-flex;

    margin-right: .75rem; 
    padding-left: 0;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.form-check-inline .form-check-input
{
    position: static;

    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
}

.valid-feedback
{
    font-size: 80%;

    display: none;

    width: 100%;
    margin-top: .25rem;

    color: #28d094;
}

.valid-tooltip
{
    font-size: .875rem;
    line-height: 1.45;

    position: absolute;
    z-index: 5;
    top: 100%;

    display: none;

    max-width: 100%;
    margin-top: .1rem;
    padding: .25rem .5rem;

    color: #2a2e30;
    border-radius: .25rem; 
    background-color: rgba(40, 208, 148, .9);
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated
.custom-select:valid,
.custom-select.is-valid
{
    border-color: #28d094;
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated
  .custom-select:valid:focus,
.custom-select.is-valid:focus
{
    border-color: #28d094;
    -webkit-box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .25);
            box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated
  .custom-select:valid ~ .valid-feedback,
.was-validated
  .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label
{
    color: #28d094;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label
{
    color: #28d094;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before
{
    background-color: #8ee9c9;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before
{
    background-color: #4eddaa;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before
{
    -webkit-box-shadow: 0 0 0 1px #f9fafd, 0 0 0 .2rem rgba(40, 208, 148, .25);
            box-shadow: 0 0 0 1px #f9fafd, 0 0 0 .2rem rgba(40, 208, 148, .25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label
{
    border-color: #28d094;
}
.was-validated .custom-file-input:valid ~ .custom-file-label::after,
.custom-file-input.is-valid ~ .custom-file-label::after
{
    border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip
{
    display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .25);
            box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .25);
}

.invalid-feedback
{
    font-size: 80%;

    display: none;

    width: 100%;
    margin-top: .25rem;

    color: #ff4961;
}

.invalid-tooltip
{
    font-size: .875rem;
    line-height: 1.45;

    position: absolute;
    z-index: 5;
    top: 100%;

    display: none;

    max-width: 100%;
    margin-top: .1rem;
    padding: .25rem .5rem;

    color: #fff;
    border-radius: .25rem; 
    background-color: rgba(255, 73, 97, .9);
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated
.custom-select:invalid,
.custom-select.is-invalid
{
    border-color: #ff4961;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated
  .custom-select:invalid:focus,
.custom-select.is-invalid:focus
{
    border-color: #ff4961;
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .25);
            box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated
  .custom-select:invalid ~ .invalid-feedback,
.was-validated
  .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label
{
    color: #ff4961;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label
{
    color: #ff4961;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before
{
    background-color: #ffc9d0;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before
{
    background-color: #ff7c8d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before
{
    -webkit-box-shadow: 0 0 0 1px #f9fafd, 0 0 0 .2rem rgba(255, 73, 97, .25);
            box-shadow: 0 0 0 1px #f9fafd, 0 0 0 .2rem rgba(255, 73, 97, .25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label
{
    border-color: #ff4961;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label::after,
.custom-file-input.is-invalid ~ .custom-file-label::after
{
    border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip
{
    display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .25);
            box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .25);
}

.form-inline
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.form-inline .form-check
{
    width: 100%;
}
@media (min-width: 576px)
{
    .form-inline label
    {
        display: -webkit-box;
        display: -webkit-flex;
        display:    -moz-box;
        display: -ms-flexbox;
        display:         flex;

        margin-bottom: 0; 

        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
        -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
        -ms-flex-pack: center;
                justify-content: center;
    }
    .form-inline .form-group
    {
        display: -webkit-box;
        display: -webkit-flex;
        display:    -moz-box;
        display: -ms-flexbox;
        display:         flex;

        margin-bottom: 0; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
           -moz-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
        -ms-flex-align: center;
                align-items: center;
    }
    .form-inline .form-control
    {
        display: inline-block;

        width: auto;

        vertical-align: middle;
    }
    .form-inline .form-control-plaintext
    {
        display: inline-block;
    }
    .form-inline .input-group,
    .form-inline .custom-select
    {
        width: auto;
    }
    .form-inline .form-check
    {
        display: -webkit-box;
        display: -webkit-flex;
        display:    -moz-box;
        display: -ms-flexbox;
        display:         flex;

        width: auto;
        padding-left: 0; 

        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
        -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
        -ms-flex-pack: center;
                justify-content: center;
    }
    .form-inline .form-check-input
    {
        position: relative;

        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0;
    }
    .form-inline .custom-control
    {
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
        -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
        -ms-flex-pack: center;
                justify-content: center;
    }
    .form-inline .custom-control-label
    {
        margin-bottom: 0;
    }
}

.btn
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;

    display: inline-block;

    padding: .75rem 1rem;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
       -moz-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
         -o-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out; 
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;

    border: 1px solid transparent;
    border-radius: .25rem;
}
@media screen and (prefers-reduced-motion: reduce)
{
    .btn
    {
        -webkit-transition: none;
           -moz-transition: none;
             -o-transition: none;
                transition: none;
    }
}
.btn:hover,
.btn:focus
{
    text-decoration: none;
}
.btn:focus,
.btn.focus
{
    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.btn.disabled,
.btn:disabled
{
    opacity: .65;
}
.btn:not(:disabled):not(.disabled)
{
    cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn
{
    pointer-events: none;
}

.btn-primary
{
    color: #fff;
    border-color: #666ee8; 
    background-color: #666ee8;
}
.btn-primary:hover
{
    color: #fff;
    border-color: #3a44e1; 
    background-color: #454ee3;
}
.btn-primary:focus,
.btn-primary.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(102, 110, 232, .5);
            box-shadow: 0 0 0 .2rem rgba(102, 110, 232, .5);
}
.btn-primary.disabled,
.btn-primary:disabled
{
    color: #fff;
    border-color: #666ee8; 
    background-color: #666ee8;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle
{
    color: #fff;
    border-color: #2f39e0; 
    background-color: #3a44e1;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(102, 110, 232, .5);
            box-shadow: 0 0 0 .2rem rgba(102, 110, 232, .5);
}

.btn-secondary
{
    color: #fff;
    border-color: #6b6f82; 
    background-color: #6b6f82;
}
.btn-secondary:hover
{
    color: #fff;
    border-color: #545766; 
    background-color: #5a5d6d;
}
.btn-secondary:focus,
.btn-secondary.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(107, 111, 130, .5);
            box-shadow: 0 0 0 .2rem rgba(107, 111, 130, .5);
}
.btn-secondary.disabled,
.btn-secondary:disabled
{
    color: #fff;
    border-color: #6b6f82; 
    background-color: #6b6f82;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle
{
    color: #fff;
    border-color: #4e515f; 
    background-color: #545766;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(107, 111, 130, .5);
            box-shadow: 0 0 0 .2rem rgba(107, 111, 130, .5);
}

.btn-success
{
    color: #2a2e30;
    border-color: #28d094; 
    background-color: #28d094;
}
.btn-success:hover
{
    color: #fff;
    border-color: #20a576; 
    background-color: #22b07d;
}
.btn-success:focus,
.btn-success.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .5);
            box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .5);
}
.btn-success.disabled,
.btn-success:disabled
{
    color: #2a2e30;
    border-color: #28d094; 
    background-color: #28d094;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle
{
    color: #fff;
    border-color: #1e9b6e; 
    background-color: #20a576;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .5);
            box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .5);
}

.btn-info
{
    color: #fff;
    border-color: #1e9ff2; 
    background-color: #1e9ff2;
}
.btn-info:hover
{
    color: #fff;
    border-color: #0c84d1; 
    background-color: #0d8bdd;
}
.btn-info:focus,
.btn-info.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(30, 159, 242, .5);
            box-shadow: 0 0 0 .2rem rgba(30, 159, 242, .5);
}
.btn-info.disabled,
.btn-info:disabled
{
    color: #fff;
    border-color: #1e9ff2; 
    background-color: #1e9ff2;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle
{
    color: #fff;
    border-color: #0b7cc5; 
    background-color: #0c84d1;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(30, 159, 242, .5);
            box-shadow: 0 0 0 .2rem rgba(30, 159, 242, .5);
}

.btn-warning
{
    color: #2a2e30;
    border-color: #ff9149; 
    background-color: #ff9149;
}
.btn-warning:hover
{
    color: #2a2e30;
    border-color: #ff7216; 
    background-color: #ff7a23;
}
.btn-warning:focus,
.btn-warning.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 145, 73, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 145, 73, .5);
}
.btn-warning.disabled,
.btn-warning:disabled
{
    color: #2a2e30;
    border-color: #ff9149; 
    background-color: #ff9149;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle
{
    color: #fff;
    border-color: #ff6a09; 
    background-color: #ff7216;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 145, 73, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 145, 73, .5);
}

.btn-danger
{
    color: #fff;
    border-color: #ff4961; 
    background-color: #ff4961;
}
.btn-danger:hover
{
    color: #fff;
    border-color: #ff1635; 
    background-color: #ff2340;
}
.btn-danger:focus,
.btn-danger.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .5);
}
.btn-danger.disabled,
.btn-danger:disabled
{
    color: #fff;
    border-color: #ff4961; 
    background-color: #ff4961;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle
{
    color: #fff;
    border-color: #ff092a; 
    background-color: #ff1635;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .5);
}

.btn-light
{
    color: #2a2e30;
    border-color: #babfc7; 
    background-color: #babfc7;
}
.btn-light:hover
{
    color: #2a2e30;
    border-color: #9ea5b0; 
    background-color: #a5abb6;
}
.btn-light:focus,
.btn-light.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5);
            box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5);
}
.btn-light.disabled,
.btn-light:disabled
{
    color: #2a2e30;
    border-color: #babfc7; 
    background-color: #babfc7;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle
{
    color: #2a2e30;
    border-color: #979eaa; 
    background-color: #9ea5b0;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5);
            box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5);
}

.btn-dark
{
    color: #fff;
    border-color: #464855; 
    background-color: #464855;
}
.btn-dark:hover
{
    color: #fff;
    border-color: #2f3039; 
    background-color: #353640;
}
.btn-dark:focus,
.btn-dark.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5);
            box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5);
}
.btn-dark.disabled,
.btn-dark:disabled
{
    color: #fff;
    border-color: #464855; 
    background-color: #464855;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle
{
    color: #fff;
    border-color: #292a32; 
    background-color: #2f3039;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5);
            box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5);
}

.btn-outline-primary
{
    color: #666ee8;
    border-color: #666ee8; 
    background-color: transparent;
    background-image: none;
}
.btn-outline-primary:hover
{
    color: #fff;
    border-color: #666ee8; 
    background-color: #666ee8;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(102, 110, 232, .5);
            box-shadow: 0 0 0 .2rem rgba(102, 110, 232, .5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled
{
    color: #666ee8;
    background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle
{
    color: #fff;
    border-color: #666ee8; 
    background-color: #666ee8;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(102, 110, 232, .5);
            box-shadow: 0 0 0 .2rem rgba(102, 110, 232, .5);
}

.btn-outline-secondary
{
    color: #6b6f82;
    border-color: #6b6f82; 
    background-color: transparent;
    background-image: none;
}
.btn-outline-secondary:hover
{
    color: #fff;
    border-color: #6b6f82; 
    background-color: #6b6f82;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(107, 111, 130, .5);
            box-shadow: 0 0 0 .2rem rgba(107, 111, 130, .5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled
{
    color: #6b6f82;
    background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle
{
    color: #fff;
    border-color: #6b6f82; 
    background-color: #6b6f82;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(107, 111, 130, .5);
            box-shadow: 0 0 0 .2rem rgba(107, 111, 130, .5);
}

.btn-outline-success
{
    color: #28d094;
    border-color: #28d094; 
    background-color: transparent;
    background-image: none;
}
.btn-outline-success:hover
{
    color: #2a2e30;
    border-color: #28d094; 
    background-color: #28d094;
}
.btn-outline-success:focus,
.btn-outline-success.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .5);
            box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled
{
    color: #28d094;
    background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle
{
    color: #2a2e30;
    border-color: #28d094; 
    background-color: #28d094;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .5);
            box-shadow: 0 0 0 .2rem rgba(40, 208, 148, .5);
}

.btn-outline-info
{
    color: #1e9ff2;
    border-color: #1e9ff2; 
    background-color: transparent;
    background-image: none;
}
.btn-outline-info:hover
{
    color: #fff;
    border-color: #1e9ff2; 
    background-color: #1e9ff2;
}
.btn-outline-info:focus,
.btn-outline-info.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(30, 159, 242, .5);
            box-shadow: 0 0 0 .2rem rgba(30, 159, 242, .5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled
{
    color: #1e9ff2;
    background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle
{
    color: #fff;
    border-color: #1e9ff2; 
    background-color: #1e9ff2;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(30, 159, 242, .5);
            box-shadow: 0 0 0 .2rem rgba(30, 159, 242, .5);
}

.btn-outline-warning
{
    color: #ff9149;
    border-color: #ff9149; 
    background-color: transparent;
    background-image: none;
}
.btn-outline-warning:hover
{
    color: #2a2e30;
    border-color: #ff9149; 
    background-color: #ff9149;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 145, 73, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 145, 73, .5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled
{
    color: #ff9149;
    background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle
{
    color: #2a2e30;
    border-color: #ff9149; 
    background-color: #ff9149;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 145, 73, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 145, 73, .5);
}

.btn-outline-danger
{
    color: #ff4961;
    border-color: #ff4961; 
    background-color: transparent;
    background-image: none;
}
.btn-outline-danger:hover
{
    color: #fff;
    border-color: #ff4961; 
    background-color: #ff4961;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled
{
    color: #ff4961;
    background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle
{
    color: #fff;
    border-color: #ff4961; 
    background-color: #ff4961;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .5);
            box-shadow: 0 0 0 .2rem rgba(255, 73, 97, .5);
}

.btn-outline-light
{
    color: #babfc7;
    border-color: #babfc7; 
    background-color: transparent;
    background-image: none;
}
.btn-outline-light:hover
{
    color: #2a2e30;
    border-color: #babfc7; 
    background-color: #babfc7;
}
.btn-outline-light:focus,
.btn-outline-light.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5);
            box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled
{
    color: #babfc7;
    background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle
{
    color: #2a2e30;
    border-color: #babfc7; 
    background-color: #babfc7;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5);
            box-shadow: 0 0 0 .2rem rgba(186, 191, 199, .5);
}

.btn-outline-dark
{
    color: #464855;
    border-color: #464855; 
    background-color: transparent;
    background-image: none;
}
.btn-outline-dark:hover
{
    color: #fff;
    border-color: #464855; 
    background-color: #464855;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5);
            box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled
{
    color: #464855;
    background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle
{
    color: #fff;
    border-color: #464855; 
    background-color: #464855;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus
{
    -webkit-box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5);
            box-shadow: 0 0 0 .2rem rgba(70, 72, 85, .5);
}

.btn-link
{
    font-weight: 400;

    color: #5059e5;
    background-color: transparent;
}
.btn-link:hover
{
    text-decoration: none;

    color: #3a44e1;
    border-color: transparent; 
    background-color: transparent;
}
.btn-link:focus,
.btn-link.focus
{
    text-decoration: none;

    border-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled
{
    pointer-events: none; 

    color: #6b6f82;
}

.btn-lg,
.btn-group-lg > .btn
{
    font-size: 1.25rem;
    line-height: 1.25;

    padding: 1rem 1.25rem;

    border-radius: .35rem;
}

.btn-sm,
.btn-group-sm > .btn
{
    font-size: .875rem;
    line-height: 1;

    padding: .5rem .75rem;

    border-radius: .21rem;
}

.btn-block
{
    display: block;

    width: 100%;
}
.btn-block + .btn-block
{
    margin-top: .5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block
{
    width: 100%;
}

.fade
{
    -webkit-transition: opacity .15s linear;
       -moz-transition: opacity .15s linear;
         -o-transition: opacity .15s linear;
            transition: opacity .15s linear;
}
@media screen and (prefers-reduced-motion: reduce)
{
    .fade
    {
        -webkit-transition: none;
           -moz-transition: none;
             -o-transition: none;
                transition: none;
    }
}
.fade:not(.show)
{
    opacity: 0;
}

.collapse:not(.show)
{
    display: none;
}

.collapsing
{
    position: relative;

    overflow: hidden;

    height: 0;

    -webkit-transition: height .35s ease;
       -moz-transition: height .35s ease;
         -o-transition: height .35s ease;
            transition: height .35s ease;
}
@media screen and (prefers-reduced-motion: reduce)
{
    .collapsing
    {
        -webkit-transition: none;
           -moz-transition: none;
             -o-transition: none;
                transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft
{
    position: relative;
}

.dropdown-toggle::after
{
    display: inline-block;

    width: 0;
    height: 0;
    margin-left: .255em;

    content: '';
    vertical-align: .255em;

    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropdown-menu
{
    font-size: 1rem;

    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;

    display: none;
    float: left;

    min-width: 10rem;
    margin: .125rem 0 0;
    padding: .5rem 0;

    list-style: none;

    text-align: left;

    color: #6b6f82;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem; 
    background-color: #fff;
    background-clip: padding-box;
}

.dropdown-menu-right
{
    right: 0;
    left: auto;
}

.dropup .dropdown-menu
{
    top: auto;
    bottom: 100%;

    margin-top: 0;
    margin-bottom: .125rem;
}

.dropup .dropdown-toggle::after
{
    display: inline-block;

    width: 0;
    height: 0;
    margin-left: .255em;

    content: '';
    vertical-align: .255em;

    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent;
}

.dropup .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropright .dropdown-menu
{
    top: 0;
    right: auto;
    left: 100%;

    margin-top: 0;
    margin-left: .125rem;
}

.dropright .dropdown-toggle::after
{
    display: inline-block;

    width: 0;
    height: 0;
    margin-left: .255em;

    content: '';
    vertical-align: .255em;

    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid;
}

.dropright .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropright .dropdown-toggle::after
{
    vertical-align: 0;
}

.dropleft .dropdown-menu
{
    top: 0;
    right: 100%;
    left: auto;

    margin-top: 0;
    margin-right: .125rem;
}

.dropleft .dropdown-toggle::after
{
    display: inline-block;

    width: 0;
    height: 0;
    margin-left: .255em;

    content: ''; 
    vertical-align: .255em;
}

.dropleft .dropdown-toggle::after
{
    display: none;
}

.dropleft .dropdown-toggle::before
{
    display: inline-block;

    width: 0;
    height: 0;
    margin-right: .255em;

    content: '';
    vertical-align: .255em;

    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after
{
    margin-left: 0;
}

.dropleft .dropdown-toggle::before
{
    vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left']
{
    right: auto;
    bottom: auto;
}

.dropdown-divider
{
    overflow: hidden;

    height: 0;
    margin: .5rem 0;

    border-top: 1px solid #98a4b8;
}

.dropdown-item
{
    font-weight: 400;

    display: block;
    clear: both;

    width: 100%;
    padding: .25rem 1.5rem;

    text-align: inherit;
    white-space: nowrap;

    color: #2a2e30;
    border: 0; 
    background-color: transparent;
}
.dropdown-item:hover,
.dropdown-item:focus
{
    text-decoration: none;

    color: #1e2122;
    background-color: #f9fafd;
}
.dropdown-item.active,
.dropdown-item:active
{
    text-decoration: none;

    color: #808080;
    background-color: transparent;
}
.dropdown-item.disabled,
.dropdown-item:disabled
{
    color: #6b6f82;
    background-color: transparent;
}

.dropdown-menu.show
{
    display: block;
}

.dropdown-header
{
    font-size: .875rem;

    display: block;

    margin-bottom: 0;
    padding: .5rem 1.5rem;

    white-space: nowrap; 

    color: #6b6f82;
}

.dropdown-item-text
{
    display: block;

    padding: .25rem 1.5rem;

    color: #2a2e30;
}

.btn-group,
.btn-group-vertical
{
    position: relative;

    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display:    -moz-inline-box;
    display: -ms-inline-flexbox;
    display:         inline-flex;

    vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn
{
    position: relative;

    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover
{
    z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active
{
    z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group
{
    margin-left: -1px;
}

.btn-toolbar
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
    -ms-flex-pack: start;
            justify-content: flex-start;
}
.btn-toolbar .input-group
{
    width: auto;
}

.btn-group > .btn:first-child
{
    margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split
{
    padding-right: .75rem;
    padding-left: .75rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after
{
    margin-left: 0;
}
.dropleft .dropdown-toggle-split::before
{
    margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split
{
    padding-right: .5625rem;
    padding-left: .5625rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split
{
    padding-right: .9375rem;
    padding-left: .9375rem;
}

.btn-group-vertical
{
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
    -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group
{
    width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group
{
    margin-top: -1px;
    margin-left: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn
{
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn
{
    margin-bottom: 0;
}
.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox']
{
    position: absolute;

    clip: rect(0, 0, 0, 0);

    pointer-events: none;
}

.input-group
{
    position: relative;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    width: 100%; 

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
       -moz-box-align: stretch;
    -ms-flex-align: stretch;
            align-items: stretch;
}
.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file
{
    position: relative;

    width: 1%;
    margin-bottom: 0; 

    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file
{
    margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label
{
    z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus
{
    z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child)
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 1rem;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child)
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group > .custom-file
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
}
.input-group-prepend .btn,
.input-group-append .btn
{
    position: relative;
    z-index: 2;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn
{
    margin-left: -1px;
}

.input-group-prepend
{
    margin-right: -1px;
}

.input-group-append
{
    margin-left: -1px;
}

.input-group-text
{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-bottom: 0;
    padding: .75rem 1rem;

    text-align: center;
    white-space: nowrap;

    color: #4e5154;
    border: 1px solid #babfc7;
    border-radius: .25rem; 
    background-color: #f9fafd;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.input-group-text input[type='radio'],
.input-group-text input[type='checkbox']
{
    margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn
{
    font-size: 1.25rem;
    line-height: 1.25;

    height: -webkit-calc(3.5625rem + 2px);
    height:    -moz-calc(3.5625rem + 2px);
    height:         calc(3.5625rem + 2px);
    padding: 1rem 1.25rem;

    border-radius: .35rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn
{
    font-size: .875rem;
    line-height: 1;

    height: -webkit-calc(1.875rem + 2px + 10);
    height:    -moz-calc(1.875rem + 2px + 10);
    height:         calc(1.875rem + 2px + 10);
    padding: .5rem .75rem;

    border-radius: .21rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child)
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child)
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control
{
    position: relative;

    display: block;

    min-height: 1.45rem;
    padding-left: 1.5rem;
}

.custom-control-inline
{
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display:    -moz-inline-box;
    display: -ms-inline-flexbox;
    display:         inline-flex;

    margin-right: 1rem;
}

.custom-control-input
{
    position: absolute;
    z-index: -1;

    opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before
{
    color: #fff;
    background-color: #1e9ff2;
}
.custom-control-input:focus ~ .custom-control-label::before
{
    -webkit-box-shadow: 0 0 0 1px #f9fafd, none;
            box-shadow: 0 0 0 1px #f9fafd, none;
}
.custom-control-input:active ~ .custom-control-label::before
{
    color: #fff;
    background-color: #c7e7fc;
}
.custom-control-input:disabled ~ .custom-control-label
{
    color: #6b6f82;
}
.custom-control-input:disabled ~ .custom-control-label::before
{
    background-color: #98a4b8;
}

.custom-control-label
{
    position: relative;

    margin-bottom: 0;
}
.custom-control-label::before
{
    position: absolute;
    top: .225rem;
    left: -1.5rem;

    display: block;

    width: 1rem;
    height: 1rem;

    content: '';
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;

    background-color: #626e82;
}
.custom-control-label::after
{
    position: absolute;
    top: .225rem;
    left: -1.5rem;

    display: block;

    width: 1rem;
    height: 1rem;

    content: '';

    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 50% 50%;
            background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before
{
    border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before
{
    background-color: #1e9ff2;
}



.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before
{
    background-color: #1e9ff2;
}



.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(102, 110, 232, .5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before
{
    background-color: rgba(102, 110, 232, .5);
}

.custom-radio .custom-control-label::before
{
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before
{
    background-color: #1e9ff2;
}



.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before
{
    background-color: rgba(102, 110, 232, .5);
}

.custom-select
{
    line-height: 1.25;

    display: inline-block;

    width: 100%;
    height: -webkit-calc(2.75rem + 2px);
    height:    -moz-calc(2.75rem + 2px);
    height:         calc(2.75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;

    vertical-align: middle;

    color: #4e5154;
    border: 1px solid #babfc7;
    border-radius: .25rem;
    -webkit-background-size: 8px 10px;
            background-size: 8px 10px;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.custom-select:focus
{
    border-color: #1e9ff2;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 5px rgba(30, 159, 242, .5);
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075), 0 0 5px rgba(30, 159, 242, .5);
}
.custom-select:focus::-ms-value
{
    color: #4e5154;
    background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size='1'])
{
    height: auto;
    padding-right: .75rem;

    background-image: none;
}
.custom-select:disabled
{
    color: #6b6f82;
    background-color: #98a4b8;
}
.custom-select::-ms-expand
{
    opacity: 0;
}

.custom-select-sm
{
    font-size: 75%; 

    height: -webkit-calc(1.875rem + 2px + 10);
    height:    -moz-calc(1.875rem + 2px + 10);
    height:         calc(1.875rem + 2px + 10);
    padding-top: .375rem;
    padding-bottom: .375rem;
}

.custom-select-lg
{
    font-size: 125%; 

    height: -webkit-calc(3.5625rem + 2px);
    height:    -moz-calc(3.5625rem + 2px);
    height:         calc(3.5625rem + 2px);
    padding-top: .375rem;
    padding-bottom: .375rem;
}

.custom-file
{
    position: relative;

    display: inline-block;

    width: 100%;
    height: -webkit-calc(2.75rem + 2px);
    height:    -moz-calc(2.75rem + 2px);
    height:         calc(2.75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input
{
    position: relative;
    z-index: 2;

    width: 100%;
    height: -webkit-calc(2.75rem + 2px);
    height:    -moz-calc(2.75rem + 2px);
    height:         calc(2.75rem + 2px);
    margin: 0;

    opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label
{
    border-color: #1e9ff2;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.custom-file-input:focus ~ .custom-file-label::after
{
    border-color: #1e9ff2;
}
.custom-file-input:disabled ~ .custom-file-label
{
    background-color: #98a4b8;
}
.custom-file-input:lang(en) ~ .custom-file-label::after
{
    content: 'Browse';
}

.custom-file-label
{
    line-height: 1.25;

    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;

    height: -webkit-calc(2.75rem + 2px);
    height:    -moz-calc(2.75rem + 2px);
    height:         calc(2.75rem + 2px);
    padding: .75rem 1rem;

    color: #4e5154;
    border: 1px solid #babfc7;
    border-radius: .25rem; 
    background-color: #fff;
}
.custom-file-label::after
{
    line-height: 1.25;

    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;

    display: block;

    height: 2.75rem;
    padding: .75rem 1rem;

    content: 'Browse';

    color: #4e5154;
    border-left: 1px solid #babfc7;
    border-radius: 0 .25rem .25rem 0; 
    background-color: #f9fafd;
}

.custom-range
{
    width: 100%;
    padding-left: 0;

    background-color: transparent;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.custom-range:focus
{
    outline: none;
}
.custom-range:focus::-webkit-slider-thumb
{
    -webkit-box-shadow: 0 0 0 1px #f9fafd, none;
            box-shadow: 0 0 0 1px #f9fafd, none;
}
.custom-range:focus::-moz-range-thumb
{
    box-shadow: 0 0 0 1px #f9fafd, none;
}
.custom-range:focus::-ms-thumb
{
    box-shadow: 0 0 0 1px #f9fafd, none;
}
.custom-range::-moz-focus-outer
{
    border: 0;
}
.custom-range::-webkit-slider-thumb
{
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;

    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
       -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
         -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #1e9ff2;

    -webkit-appearance: none;
            appearance: none;
}
@media screen and (prefers-reduced-motion: reduce)
{
    .custom-range::-webkit-slider-thumb
    {
        -webkit-transition: none;
           -moz-transition: none;
             -o-transition: none;
                transition: none;
    }
}
.custom-range::-webkit-slider-thumb:active
{
    background-color: #c7e7fc;
}
.custom-range::-webkit-slider-runnable-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-color: transparent;
    border-radius: 1rem; 
    background-color: #626e82;
}
.custom-range::-moz-range-thumb
{
    width: 1rem;
    height: 1rem;

    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
       -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
         -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #1e9ff2;

    -moz-appearance: none;
         appearance: none;
}
@media screen and (prefers-reduced-motion: reduce)
{
    .custom-range::-moz-range-thumb
    {
        -webkit-transition: none;
           -moz-transition: none;
             -o-transition: none;
                transition: none;
    }
}
.custom-range::-moz-range-thumb:active
{
    background-color: #c7e7fc;
}
.custom-range::-moz-range-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-color: transparent;
    border-radius: 1rem; 
    background-color: #626e82;
}
.custom-range::-ms-thumb
{
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: .2rem;
    margin-left: .2rem;

    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
       -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
         -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;

    border: 0;
    border-radius: 1rem;
    background-color: #1e9ff2;

    appearance: none;
}
@media screen and (prefers-reduced-motion: reduce)
{
    .custom-range::-ms-thumb
    {
        -webkit-transition: none;
           -moz-transition: none;
             -o-transition: none;
                transition: none;
    }
}
.custom-range::-ms-thumb:active
{
    background-color: #c7e7fc;
}
.custom-range::-ms-track
{
    width: 100%;
    height: .5rem;

    cursor: pointer;

    color: transparent;
    border-width: .5rem; 
    border-color: transparent;
    background-color: transparent;
}
.custom-range::-ms-fill-lower
{
    border-radius: 1rem; 
    background-color: #626e82;
}
.custom-range::-ms-fill-upper
{
    margin-right: 15px;

    border-radius: 1rem; 
    background-color: #626e82;
}

.custom-control-label::before,
.custom-file-label,
.custom-select
{
    -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
       -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
         -o-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce)
{
    .custom-control-label::before,
    .custom-file-label,
    .custom-select
    {
        -webkit-transition: none;
           -moz-transition: none;
             -o-transition: none;
                transition: none;
    }
}

.nav
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-bottom: 0;
    padding-left: 0;

    list-style: none; 

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.nav-link
{
    display: block;

    padding: .5rem 1rem;
}
.nav-link:hover,
.nav-link:focus
{
    text-decoration: none;
}
.nav-link.disabled
{
    color: #6b6f82;
}

.nav-tabs
{
    border-bottom: 1px solid #babfc7;
}
.nav-tabs .nav-item
{
    margin-bottom: -1px;
}
.nav-tabs .nav-link
{
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus
{
    border-color: #babfc7 #babfc7 #babfc7;
}
.nav-tabs .nav-link.disabled
{
    color: #6b6f82;
    border-color: transparent; 
    background-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link
{
    color: #4e5154;
    border-color: #babfc7 #babfc7 #f9fafd; 
    background-color: #f9fafd;
}
.nav-tabs .dropdown-menu
{
    margin-top: -1px;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link
{
    border-radius: .25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link
{
    color: #fff;
    background-color: #1e9ff2;
}

.nav-fill .nav-item
{
    text-align: center; 

    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}

.nav-justified .nav-item
{
    text-align: center; 

    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
    -ms-flex-positive: 1;
            flex-grow: 1;
}

.tab-content > .tab-pane
{
    display: none;
}

.tab-content > .active
{
    display: block;
}

.navbar
{
    position: relative;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    padding: .5rem 1rem; 

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
}
.navbar > .container,
.navbar > .container-fluid
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
}

.navbar-brand
{
    font-size: 1.25rem;
    line-height: inherit;

    display: inline-block;

    margin-right: 1rem;
    padding-top: .31875rem;
    padding-bottom: .31875rem;

    white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus
{
    text-decoration: none;
}

.navbar-nav
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
            flex-direction: column;

    margin-bottom: 0;
    padding-left: 0;

    list-style: none; 

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
}
.navbar-nav .nav-link
{
    padding-right: 0;
    padding-left: 0;
}
.navbar-nav .dropdown-menu
{
    position: static;

    float: none;
}

.navbar-text
{
    display: inline-block;

    padding-top: .5rem;
    padding-bottom: .5rem;
}

.navbar-collapse
{
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
       -moz-box-flex: 1;
    -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}

.navbar-toggler
{
    font-size: 1.25rem;
    line-height: 1;

    padding: .25rem .75rem;

    border: 1px solid transparent;
    border-radius: .25rem; 
    background-color: transparent;
}
.navbar-toggler:hover,
.navbar-toggler:focus
{
    text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled)
{
    cursor: pointer;
}

.navbar-toggler-icon
{
    display: inline-block;

    width: 1.5em;
    height: 1.5em;

    content: '';
    vertical-align: middle;

    background: no-repeat center center;
    -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
}

@media (max-width: 575.98px)
{
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px)
{
    .navbar-expand-sm
    {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row nowrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
           -moz-box-pack: start;
        -ms-flex-pack: start;
                justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav
    {
                flex-direction: row; 

        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid
    {
        -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse
    {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display:    -moz-box !important;
        display: -ms-flexbox !important;
        display:         flex !important;

        -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
                flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 767.98px)
{
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px)
{
    .navbar-expand-md
    {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row nowrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
           -moz-box-pack: start;
        -ms-flex-pack: start;
                justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav
    {
                flex-direction: row; 

        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu
    {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid
    {
        -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse
    {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display:    -moz-box !important;
        display: -ms-flexbox !important;
        display:         flex !important;

        -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
                flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 991.98px)
{
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px)
{
    .navbar-expand-lg
    {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row nowrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
           -moz-box-pack: start;
        -ms-flex-pack: start;
                justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav
    {
                flex-direction: row; 

        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid
    {
        -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse
    {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display:    -moz-box !important;
        display: -ms-flexbox !important;
        display:         flex !important;

        -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
                flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler
    {
        display: none;
    }
}

@media (max-width: 1199.98px)
{
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid
    {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px)
{
    .navbar-expand-xl
    {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row nowrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row nowrap;
                flex-flow: row nowrap;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
           -moz-box-pack: start;
        -ms-flex-pack: start;
                justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav
    {
                flex-direction: row; 

        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link
    {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid
    {
        -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse
    {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display:    -moz-box !important;
        display: -ms-flexbox !important;
        display:         flex !important;

        -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
                flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler
    {
        display: none;
    }
}

.navbar-expand
{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
    -ms-flex-pack: start;
            justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid
{
    padding-right: 0;
    padding-left: 0;
}
.navbar-expand .navbar-nav
{
            flex-direction: row; 

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu
{
    position: absolute;
}
.navbar-expand .navbar-nav .nav-link
{
    padding-right: .5rem;
    padding-left: .5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid
{
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse
{
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display:    -moz-box !important;
    display: -ms-flexbox !important;
    display:         flex !important;

    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
            flex-basis: auto;
}
.navbar-expand .navbar-toggler
{
    display: none;
}

.navbar-light .navbar-brand
{
    color: #464855;
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus
{
    color: #464855;
}

.navbar-light .navbar-nav .nav-link
{
    color: #6b6f82;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus
{
    color: #626e82;
}
.navbar-light .navbar-nav .nav-link.disabled
{
    color: #f9fafd;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active
{
    color: #464855;
}

.navbar-light .navbar-toggler
{
    color: #6b6f82;
    border-color: rgba(0, 0, 0, .1);
}


.navbar-light .navbar-text
{
    color: #6b6f82;
}
.navbar-light .navbar-text a
{
    color: #464855;
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus
{
    color: #464855;
}

.navbar-dark .navbar-brand
{
    color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus
{
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link
{
    color: white;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus
{
    color: rgba(255, 255, 255, .9);
}
.navbar-dark .navbar-nav .nav-link.disabled
{
    color: rgba(255, 255, 255, .5);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active
{
    color: #fff;
}

.navbar-dark .navbar-toggler
{
    color: white;
    border-color: rgba(255, 255, 255, .1);
}



.navbar-dark .navbar-text
{
    color: white;
}
.navbar-dark .navbar-text a
{
    color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus
{
    color: #fff;
}

.card
{
    position: relative;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
            flex-direction: column;

    min-width: 0;

    word-wrap: break-word;

    border: 1px solid rgba(0, 0, 0, .06);
    border-radius: .35rem; 
    background-color: #fff;
    background-clip: border-box;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
}
.card > hr
{
    margin-right: 0;
    margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child
{
    border-top-left-radius: .35rem;
    border-top-right-radius: .35rem;
}
.card > .list-group:last-child .list-group-item:last-child
{
    border-bottom-right-radius: .35rem;
    border-bottom-left-radius: .35rem;
}

.card-body
{
    padding: 1.5rem; 

    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}

.card-title
{
    margin-bottom: 1.5rem;
}

.card-subtitle
{
    margin-top: -.75rem;
    margin-bottom: 0;
}

.card-text:last-child
{
    margin-bottom: 0;
}

.card-link:hover
{
    text-decoration: none;
}

.card-link + .card-link
{
    margin-left: 1.5rem;
}

.card-header
{
    margin-bottom: 0;
    padding: 1.5rem 1.5rem;

    border-bottom: 1px solid rgba(0, 0, 0, .06); 
    background-color: #fff;
}
.card-header:first-child
{
    border-radius: -webkit-calc(.35rem - 1px) -webkit-calc(.35rem - 1px) 0 0;
    border-radius:    -moz-calc(.35rem - 1px) -moz-calc(.35rem - 1px) 0 0;
    border-radius:         calc(.35rem - 1px) calc(.35rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child
{
    border-top: 0;
}

.card-footer
{
    padding: 1.5rem 1.5rem;

    border-top: 1px solid rgba(0, 0, 0, .06); 
    background-color: #fff;
}
.card-footer:last-child
{
    border-radius: 0 0 -webkit-calc(.35rem - 1px) -webkit-calc(.35rem - 1px);
    border-radius: 0 0 -moz-calc(.35rem - 1px) -moz-calc(.35rem - 1px);
    border-radius: 0 0 calc(.35rem - 1px) calc(.35rem - 1px);
}

.card-header-tabs
{
    margin-right: -.75rem;
    margin-bottom: -1.5rem;
    margin-left: -.75rem;

    border-bottom: 0;
}

.card-header-pills
{
    margin-right: -.75rem;
    margin-left: -.75rem;
}

.card-img-overlay
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 1.25rem;
}

.card-img
{
    width: 100%;

    border-radius: -webkit-calc(.35rem - 1px);
    border-radius:    -moz-calc(.35rem - 1px);
    border-radius:         calc(.35rem - 1px);
}

.card-img-top
{
    width: 100%;

    border-top-left-radius: -webkit-calc(.35rem - 1px);
    border-top-left-radius:    -moz-calc(.35rem - 1px);
    border-top-left-radius:         calc(.35rem - 1px);
    border-top-right-radius: -webkit-calc(.35rem - 1px);
    border-top-right-radius:    -moz-calc(.35rem - 1px);
    border-top-right-radius:         calc(.35rem - 1px);
}

.card-img-bottom
{
    width: 100%;

    border-bottom-right-radius: -webkit-calc(.35rem - 1px);
    border-bottom-right-radius:    -moz-calc(.35rem - 1px);
    border-bottom-right-radius:         calc(.35rem - 1px);
    border-bottom-left-radius: -webkit-calc(.35rem - 1px);
    border-bottom-left-radius:    -moz-calc(.35rem - 1px);
    border-bottom-left-radius:         calc(.35rem - 1px);
}

.card-deck
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
            flex-direction: column; 

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
}
.card-deck .card
{
    margin-bottom: 15px;
}
@media (min-width: 576px)
{
    .card-deck
    {
        margin-right: -15px;
        margin-left: -15px; 

        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
    }
    .card-deck .card
    {
        display: -webkit-box;
        display: -webkit-flex;
        display:    -moz-box;
        display: -ms-flexbox;
        display:         flex;
                flex-direction: column;

        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; 

        -webkit-box-flex: 1;
        -webkit-flex: 1 0 0;
           -moz-box-flex: 1;
            -ms-flex: 1 0 0;
                flex: 1 0 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
    }
}

.card-group
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
            flex-direction: column; 

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
}
.card-group > .card
{
    margin-bottom: 15px;
}
@media (min-width: 576px)
{
    .card-group
    {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
    }
    .card-group > .card
    {
        margin-bottom: 0; 

        -webkit-box-flex: 1;
        -webkit-flex: 1 0 0;
           -moz-box-flex: 1;
            -ms-flex: 1 0 0;
                flex: 1 0 0;
    }
    .card-group > .card + .card
    {
        margin-left: 0;

        border-left: 0;
    }
    .card-group > .card:first-child
    {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group > .card:first-child .card-img-top,
    .card-group > .card:first-child .card-header
    {
        border-top-right-radius: 0;
    }
    .card-group > .card:first-child .card-img-bottom,
    .card-group > .card:first-child .card-footer
    {
        border-bottom-right-radius: 0;
    }
    .card-group > .card:last-child
    {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group > .card:last-child .card-img-top,
    .card-group > .card:last-child .card-header
    {
        border-top-left-radius: 0;
    }
    .card-group > .card:last-child .card-img-bottom,
    .card-group > .card:last-child .card-footer
    {
        border-bottom-left-radius: 0;
    }
    .card-group > .card:only-child
    {
        border-radius: .35rem;
    }
    .card-group > .card:only-child .card-img-top,
    .card-group > .card:only-child .card-header
    {
        border-top-left-radius: .35rem;
        border-top-right-radius: .35rem;
    }
    .card-group > .card:only-child .card-img-bottom,
    .card-group > .card:only-child .card-footer
    {
        border-bottom-right-radius: .35rem;
        border-bottom-left-radius: .35rem;
    }
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child)
    {
        border-radius: 0;
    }
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer
    {
        border-radius: 0;
    }
}

.card-columns .card
{
    margin-bottom: 1.5rem;
}

@media (min-width: 576px)
{
    .card-columns
    {
        -webkit-column-count: 3;
           -moz-column-count: 3;
                column-count: 3;
        -webkit-column-gap: 1.25rem;
           -moz-column-gap: 1.25rem;
                column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }
    .card-columns .card
    {
        display: inline-block;

        width: 100%;
    }
}

.accordion .card:not(:first-of-type):not(:last-of-type)
{
    border-bottom: 0;
    border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child
{
    border-radius: 0;
}

.accordion .card:first-of-type
{
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion .card:last-of-type
{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.breadcrumb
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-bottom: 1rem;
    padding: .75rem 0rem;

    list-style: none;

    border-radius: .25rem; 
    background-color: #98a4b8;

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.breadcrumb-item + .breadcrumb-item
{
    padding-left: .5rem;
}
.breadcrumb-item + .breadcrumb-item::before
{
    display: inline-block;

    padding-right: .5rem;

    content: '/'; 

    color: #6b6f82;
}

.breadcrumb-item + .breadcrumb-item:hover::before
{
    text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before
{
    text-decoration: none;
}

.breadcrumb-item.active
{
    color: #6b6f82;
}

.pagination
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    padding-left: 0;

    list-style: none;

    border-radius: .25rem;
}

.page-link
{
    line-height: 1;
    position: relative;
    display: block;
    margin-left: -1px;
    padding: .5rem .75rem;
    color: #5059e5;
    border: 1px solid #626e82; 
    background-color: #fff;
}
.page-link:hover
{
    z-index: 2;
    text-decoration: none;
    color: #3a44e1;
    border-color: #626e82; 
    background-color: #f9fafd;
}
.page-link:focus
{
    z-index: 2;

    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.page-link:not(:disabled):not(.disabled)
{
    cursor: pointer;
}

.page-item:first-child .page-link
{
    margin-left: 0;

    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.page-item:last-child .page-link
{
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.page-item.active .page-link
{
    z-index: 1;
    color: #fff;
    border-color: #1e9ff2; 
    background-color: #1e9ff2;
}

.page-item.disabled .page-link
{
    cursor: auto;
    pointer-events: none;

    color: #6b6f82;
    border-color: #626e82; 
    background-color: #fff;
}

.pagination-lg .page-link
{
    font-size: 1.25rem;
    line-height: 1.25; 

    padding: .75rem 1.5rem;
}

.pagination-lg .page-item:first-child .page-link
{
    border-top-left-radius: .35rem;
    border-bottom-left-radius: .35rem;
}

.pagination-lg .page-item:last-child .page-link
{
    border-top-right-radius: .35rem;
    border-bottom-right-radius: .35rem;
}

.pagination-sm .page-link
{
    font-size: .875rem;
    line-height: 1; 

    padding: .25rem .5rem;
}

.pagination-sm .page-item:first-child .page-link
{
    border-top-left-radius: .21rem;
    border-bottom-left-radius: .21rem;
}

.pagination-sm .page-item:last-child .page-link
{
    border-top-right-radius: .21rem;
    border-bottom-right-radius: .21rem;
}

.badge
{
    font-size: 85%;
    font-weight: 700;
    line-height: 1;

    display: inline-block;

    padding: .35em .4em;

    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;

    border-radius: .25rem;
}
.badge:empty
{
    display: none;
}

.btn .badge
{
    position: relative;
    top: -1px;
}

.badge-pill
{
    padding-right: .6em;
    padding-left: .6em;

    border-radius: 10rem;
}

.badge-primary
{
    color: #fff;
    background-color: #666ee8;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus
{
    text-decoration: none;

    color: #fff;
    background-color: #3a44e1;
}

.badge-secondary
{
    color: #fff;
    background-color: #6b6f82;
}
.badge-secondary[href]:hover,
.badge-secondary[href]:focus
{
    text-decoration: none;

    color: #fff;
    background-color: #545766;
}

.badge-success
{
    color: #2a2e30;
    background-color: #28d094;
}
.badge-success[href]:hover,
.badge-success[href]:focus
{
    text-decoration: none;

    color: #2a2e30;
    background-color: #20a576;
}

.badge-info
{
    color: #fff;
    background-color: #1e9ff2;
}
.badge-info[href]:hover,
.badge-info[href]:focus
{
    text-decoration: none;

    color: #fff;
    background-color: #0c84d1;
}

.badge-warning
{
    color: #2a2e30;
    background-color: #ff9149;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus
{
    text-decoration: none;

    color: #2a2e30;
    background-color: #ff7216;
}

.badge-danger
{
    color: #fff;
    background-color: #ff4961;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus
{
    text-decoration: none;

    color: #fff;
    background-color: #ff1635;
}

.badge-light
{
    color: #2a2e30;
    background-color: #babfc7;
}
.badge-light[href]:hover,
.badge-light[href]:focus
{
    text-decoration: none;

    color: #2a2e30;
    background-color: #9ea5b0;
}

.badge-dark
{
    color: #fff;
    background-color: #464855;
}
.badge-dark[href]:hover,
.badge-dark[href]:focus
{
    text-decoration: none;

    color: #fff;
    background-color: #2f3039;
}

.jumbotron
{
    margin-bottom: 2rem;
    padding: 2rem 1rem;

    border-radius: .35rem; 
    background-color: #98a4b8;
}
@media (min-width: 576px)
{
    .jumbotron
    {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid
{
    padding-right: 0;
    padding-left: 0;

    border-radius: 0;
}

.alert
{
    position: relative;

    margin-bottom: 1rem;
    padding: .75rem 1rem;

    border: 1px solid transparent;
    border-radius: .25rem;
}

.alert-heading
{
    color: inherit;
}

.alert-link
{
    font-weight: 700;
}

.alert-dismissible
{
    padding-right: 3.5rem;
}
.alert-dismissible .close
{
    position: absolute;
    top: 0;
    right: 0;

    padding: .75rem 1rem;

    color: inherit;
}

.alert-primary
{
    color: #353979;
    border-color: #d4d6f9; 
    background-color: #e0e2fa;
}
.alert-primary hr
{
    border-top-color: #bec1f6;
}
.alert-primary .alert-link
{
    color: #252856;
}

.alert-secondary
{
    color: #383a44;
    border-color: #d6d7dc; 
    background-color: #e1e2e6;
}
.alert-secondary hr
{
    border-top-color: #c8cad0;
}
.alert-secondary .alert-link
{
    color: #212228;
}

.alert-success
{
    color: #156c4d;
    border-color: #c3f2e1; 
    background-color: #d4f6ea;
}
.alert-success hr
{
    border-top-color: #aeedd7;
}
.alert-success .alert-link
{
    color: #0d412f;
}

.alert-info
{
    color: #10537e;
    border-color: #c0e4fb; 
    background-color: #d2ecfc;
}
.alert-info hr
{
    border-top-color: #a8daf9;
}
.alert-info .alert-link
{
    color: #0a3551;
}

.alert-warning
{
    color: #854b26;
    border-color: #ffe0cc; 
    background-color: #ffe9db;
}
.alert-warning hr
{
    border-top-color: #ffd1b3;
}
.alert-warning .alert-link
{
    color: #5d351b;
}

.alert-danger
{
    color: #852632;
    border-color: #ffccd3; 
    background-color: #ffdbdf;
}
.alert-danger hr
{
    border-top-color: #ffb3bd;
}
.alert-danger .alert-link
{
    color: #5d1b23;
}

.alert-light
{
    color: #616367;
    border-color: #ecedef; 
    background-color: #f1f2f4;
}
.alert-light hr
{
    border-top-color: #dee0e3;
}
.alert-light .alert-link
{
    color: #484a4d;
}

.alert-dark
{
    color: #24252c;
    border-color: #cbcccf; 
    background-color: #dadadd;
}
.alert-dark hr
{
    border-top-color: #bebfc3;
}
.alert-dark .alert-link
{
    color: #0d0d10;
}

@-webkit-keyframes progress-bar-stripes
{
    from
    {
        background-position: 1rem 0;
    }
    to
    {
        background-position: 0 0;
    }
}

@-moz-keyframes progress-bar-stripes
{
    from
    {
        background-position: 1rem 0;
    }
    to
    {
        background-position: 0 0;
    }
}

@-o-keyframes progress-bar-stripes
{
    from
    {
        background-position: 1rem 0;
    }
    to
    {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes
{
    from
    {
        background-position: 1rem 0;
    }
    to
    {
        background-position: 0 0;
    }
}

.progress
{
    font-size: .75rem;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    overflow: hidden;

    height: 1rem;

    border-radius: .25rem; 
    background-color: #eee;
}

.progress-bar
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
            flex-direction: column;

    -webkit-transition: width .6s ease;
       -moz-transition: width .6s ease;
         -o-transition: width .6s ease;
            transition: width .6s ease; 
    text-align: center;
    white-space: nowrap;

    color: #fff;
    background-color: #666ee8;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
}
@media screen and (prefers-reduced-motion: reduce)
{
    .progress-bar
    {
        -webkit-transition: none;
           -moz-transition: none;
             -o-transition: none;
                transition: none;
    }
}

.progress-bar-striped
{
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    -webkit-background-size: 1rem 1rem;
            background-size: 1rem 1rem;
}

.progress-bar-animated
{
    -webkit-animation: progress-bar-stripes 1s linear infinite;
       -moz-animation: progress-bar-stripes 1s linear infinite;
         -o-animation: progress-bar-stripes 1s linear infinite;
            animation: progress-bar-stripes 1s linear infinite;
}

.media
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
    -ms-flex-align: start;
            align-items: flex-start;
}

.media-body
{
    -webkit-box-flex: 1;
    -webkit-flex: 1;
       -moz-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.list-group
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
            flex-direction: column;

    margin-bottom: 0; 
    padding-left: 0;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
}

.list-group-item-action
{
    width: 100%;

    text-align: inherit; 

    color: #4e5154;
}
.list-group-item-action:hover,
.list-group-item-action:focus
{
    text-decoration: none;

    color: #4e5154;
    background-color: #f9fafd;
}
.list-group-item-action:active
{
    color: #6b6f82;
    background-color: #98a4b8;
}

.list-group-item
{
    position: relative;

    display: block;

    margin-bottom: -1px;
    padding: 1.25rem 1.25rem;

    border: 1px solid #e4e7ed; 
    background-color: #fff;
}
.list-group-item:first-child
{
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}
.list-group-item:last-child
{
    margin-bottom: 0;

    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
.list-group-item:hover,
.list-group-item:focus
{
    z-index: 1;

    text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled
{
    color: #6b6f82;
    background-color: #fff;
}
.list-group-item.active
{
    z-index: 2;

    color: #fff;
    border-color: #1e9ff2; 
    background-color: #1e9ff2;
}

.list-group-flush .list-group-item
{
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child
{
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child
{
    border-bottom: 0;
}

.list-group-item-primary
{
    color: #353979;
    background-color: #d4d6f9;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus
{
    color: #353979;
    background-color: #bec1f6;
}
.list-group-item-primary.list-group-item-action.active
{
    color: #fff;
    border-color: #353979; 
    background-color: #353979;
}

.list-group-item-secondary
{
    color: #383a44;
    background-color: #d6d7dc;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus
{
    color: #383a44;
    background-color: #c8cad0;
}
.list-group-item-secondary.list-group-item-action.active
{
    color: #fff;
    border-color: #383a44; 
    background-color: #383a44;
}

.list-group-item-success
{
    color: #156c4d;
    background-color: #c3f2e1;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus
{
    color: #156c4d;
    background-color: #aeedd7;
}
.list-group-item-success.list-group-item-action.active
{
    color: #fff;
    border-color: #156c4d; 
    background-color: #156c4d;
}

.list-group-item-info
{
    color: #10537e;
    background-color: #c0e4fb;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus
{
    color: #10537e;
    background-color: #a8daf9;
}
.list-group-item-info.list-group-item-action.active
{
    color: #fff;
    border-color: #10537e; 
    background-color: #10537e;
}

.list-group-item-warning
{
    color: #854b26;
    background-color: #ffe0cc;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus
{
    color: #854b26;
    background-color: #ffd1b3;
}
.list-group-item-warning.list-group-item-action.active
{
    color: #fff;
    border-color: #854b26; 
    background-color: #854b26;
}

.list-group-item-danger
{
    color: #852632;
    background-color: #ffccd3;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus
{
    color: #852632;
    background-color: #ffb3bd;
}
.list-group-item-danger.list-group-item-action.active
{
    color: #fff;
    border-color: #852632; 
    background-color: #852632;
}

.list-group-item-light
{
    color: #616367;
    background-color: #ecedef;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus
{
    color: #616367;
    background-color: #dee0e3;
}
.list-group-item-light.list-group-item-action.active
{
    color: #fff;
    border-color: #616367; 
    background-color: #616367;
}

.list-group-item-dark
{
    color: #24252c;
    background-color: #cbcccf;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus
{
    color: #24252c;
    background-color: #bebfc3;
}
.list-group-item-dark.list-group-item-action.active
{
    color: #fff;
    border-color: #24252c; 
    background-color: #24252c;
}

.close
{
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;

    float: right;

    opacity: .5; 
    color: #000;
    text-shadow: 0 1px 0 #fff;
}
.close:not(:disabled):not(.disabled)
{
    cursor: pointer;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus
{
    text-decoration: none;

    opacity: .75; 
    color: #000;
}

button.close
{
    padding: 0;

    border: 0;
    background-color: transparent;

    -webkit-appearance: none;
}

.modal-open
{
    overflow: hidden;
}
.modal-open .modal
{
    overflow-x: hidden;
    overflow-y: auto;
}

.modal
{
    position: fixed;
    z-index: 1050;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    overflow: hidden;

    outline: 0;
}

.modal-dialog
{
    position: relative;

    width: auto;
    margin: .5rem;

    pointer-events: none;
}
.modal.fade .modal-dialog
{
    -webkit-transition: -webkit-transform .3s ease-out;
       -moz-transition:         transform .3s ease-out, -moz-transform .3s ease-out;
         -o-transition:      -o-transform .3s ease-out;
            transition: -webkit-transform .3s ease-out;
            transition:         transform .3s ease-out;
            transition:         transform .3s ease-out, -webkit-transform .3s ease-out, -moz-transform .3s ease-out, -o-transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
       -moz-transform: translate(0, -25%);
        -ms-transform: translate(0, -25%);
         -o-transform: translate(0, -25%);
            transform: translate(0, -25%);
}
@media screen and (prefers-reduced-motion: reduce)
{
    .modal.fade .modal-dialog
    {
        -webkit-transition: none;
           -moz-transition: none;
             -o-transition: none;
                transition: none;
    }
}
.modal.show .modal-dialog
{
    -webkit-transform: translate(0, 0);
       -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
         -o-transform: translate(0, 0);
            transform: translate(0, 0);
}

.modal-dialog-centered
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    min-height: -webkit-calc(100% - (.5rem * 2));
    min-height:    -moz-calc(100% - (.5rem * 2));
    min-height:         calc(100% - (.5rem * 2)); 

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.modal-dialog-centered::before
{
    display: block;

    height: -webkit-calc(100vh - (.5rem * 2));
    height:    -moz-calc(100vh - (.5rem * 2));
    height:         calc(100vh - (.5rem * 2));

    content: '';
}

.modal-content
{
    position: relative;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
            flex-direction: column;

    width: 100%;

    pointer-events: auto;

    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .35rem;
    outline: 0; 
    background-color: #fff;
    background-clip: padding-box;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
}

.modal-backdrop
{
    position: fixed;
    z-index: 1040;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: #000;
}
.modal-backdrop.fade
{
    opacity: 0;
}
.modal-backdrop.show
{
    opacity: .5;
}

.modal-header
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    padding: 2rem;


    border-bottom: 1px solid #98a4b8;
    border-top-left-radius: .35rem;
    border-top-right-radius: .35rem; 

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
    -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
}
.modal-header .close
{
    margin: -1rem -1rem -1rem auto; 
    padding: 1rem;
}

.modal-title
{
    line-height: 1.45; 

    margin-bottom: 0;
}

.modal-body
{
    position: relative;

    padding: 1rem; 

    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}

.modal-footer
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    padding: 1rem;

    border-top: 1px solid #98a4b8; 

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
    -ms-flex-pack: end;
            justify-content: flex-end;
}
.modal-footer > :not(:first-child)
{
    margin-left: .25rem;
}
.modal-footer > :not(:last-child)
{
    margin-right: .25rem;
}

.modal-scrollbar-measure
{
    position: absolute;
    top: -9999px;

    overflow: scroll; 

    width: 50px;
    height: 50px;
}

@media (min-width: 576px)
{
    .modal-dialog
    {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-centered
    {
        min-height: -webkit-calc(100% - (1.75rem * 2));
        min-height:    -moz-calc(100% - (1.75rem * 2));
        min-height:         calc(100% - (1.75rem * 2));
    }
    .modal-dialog-centered::before
    {
        height: -webkit-calc(100vh - (1.75rem * 2));
        height:    -moz-calc(100vh - (1.75rem * 2));
        height:         calc(100vh - (1.75rem * 2));
    }
    .modal-sm
    {
        max-width: 300px;
    }
}

@media (min-width: 992px)
{
    .modal-lg
    {
        max-width: 800px;
    }
}

.tooltip
{
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.45;

    position: absolute;
    z-index: 1070;

    display: block;

    margin: 0;

    text-align: left;
    text-align: start;
    white-space: normal;
    text-decoration: none;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    word-wrap: break-word;
    word-break: normal;

    opacity: 0; 
    text-shadow: none;

    line-break: auto;
}
.tooltip.show
{
    opacity: .9;
}
.tooltip .arrow
{
    position: absolute;

    display: block;

    width: .8rem;
    height: .4rem;
}
.tooltip .arrow::before
{
    position: absolute;

    content: '';

    border-style: solid; 
    border-color: transparent;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top']
{
    padding: .4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow
{
    bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before
{
    top: 0;

    border-width: .4rem .4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right']
{
    padding: 0 .4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow
{
    left: 0;

    width: .4rem;
    height: .8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before
{
    right: 0;

    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom']
{
    padding: .4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow
{
    top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before
{
    bottom: 0;

    border-width: 0 .4rem .4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left']
{
    padding: 0 .4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow
{
    right: 0;

    width: .4rem;
    height: .8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before
{
    left: 0;

    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000;
}

.tooltip-inner
{
    max-width: 200px;
    padding: .25rem .5rem;

    text-align: center;

    color: #fff;
    border-radius: .25rem; 
    background-color: #000;
}

.popover
{
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.45;

    position: absolute;
    z-index: 1060;
    top: 0;
    left: 0;

    display: block;

    max-width: 276px;

    text-align: left;
    text-align: start;
    white-space: normal;
    text-decoration: none;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    word-wrap: break-word;
    word-break: normal;

    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .35rem; 
    background-color: #fff;
    background-clip: padding-box;
    text-shadow: none;

    line-break: auto;
}
.popover .arrow
{
    position: absolute;

    display: block;

    width: 1rem;
    height: .5rem;
    margin: 0 .35rem;
}
.popover .arrow::before,
.popover .arrow::after
{
    position: absolute;

    display: block;

    content: '';

    border-style: solid; 
    border-color: transparent;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top']
{
    margin-bottom: .5rem;
}
.bs-popover-top .arrow,
.bs-popover-auto[x-placement^='top'] .arrow
{
    bottom: -webkit-calc((.5rem + 1px) * -1);
    bottom:    -moz-calc((.5rem + 1px) * -1);
    bottom:         calc((.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^='top'] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::after
{
    border-width: .5rem .5rem 0;
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^='top'] .arrow::before
{
    bottom: 0;

    border-top-color: rgba(0, 0, 0, .25);
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::after
{
    bottom: 1px;

    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right']
{
    margin-left: .5rem;
}
.bs-popover-right .arrow,
.bs-popover-auto[x-placement^='right'] .arrow
{
    left: -webkit-calc((.5rem + 1px) * -1);
    left:    -moz-calc((.5rem + 1px) * -1);
    left:         calc((.5rem + 1px) * -1);

    width: .5rem;
    height: 1rem;
    margin: .35rem 0;
}
.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^='right'] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::after
{
    border-width: .5rem .5rem .5rem 0;
}
.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^='right'] .arrow::before
{
    left: 0;

    border-right-color: rgba(0, 0, 0, .25);
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::after
{
    left: 1px;

    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom']
{
    margin-top: .5rem;
}
.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^='bottom'] .arrow
{
    top: -webkit-calc((.5rem + 1px) * -1);
    top:    -moz-calc((.5rem + 1px) * -1);
    top:         calc((.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^='bottom'] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    border-width: 0 .5rem .5rem .5rem;
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^='bottom'] .arrow::before
{
    top: 0;

    border-bottom-color: rgba(0, 0, 0, .25);
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^='bottom'] .arrow::after
{
    top: 1px;

    border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before
{
    position: absolute;
    top: 0;
    left: 50%;

    display: block;

    width: 1rem;
    margin-left: -.5rem;

    content: '';

    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left']
{
    margin-right: .5rem;
}
.bs-popover-left .arrow,
.bs-popover-auto[x-placement^='left'] .arrow
{
    right: -webkit-calc((.5rem + 1px) * -1);
    right:    -moz-calc((.5rem + 1px) * -1);
    right:         calc((.5rem + 1px) * -1);

    width: .5rem;
    height: 1rem;
    margin: .35rem 0;
}
.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^='left'] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^='left'] .arrow::after
{
    border-width: .5rem 0 .5rem .5rem;
}
.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^='left'] .arrow::before
{
    right: 0;

    border-left-color: rgba(0, 0, 0, .25);
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^='left'] .arrow::after
{
    right: 1px;

    border-left-color: #fff;
}

.popover-header
{
    font-size: 1rem;

    margin-bottom: 0;
    padding: .5rem .75rem;

    color: #464855;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: -webkit-calc(.35rem - 1px);
    border-top-left-radius:    -moz-calc(.35rem - 1px);
    border-top-left-radius:         calc(.35rem - 1px);
    border-top-right-radius: -webkit-calc(.35rem - 1px);
    border-top-right-radius:    -moz-calc(.35rem - 1px);
    border-top-right-radius:         calc(.35rem - 1px); 
    background-color: #f7f7f7;
}
.popover-header:empty
{
    display: none;
}

.popover-body
{
    padding: .5rem .75rem;

    color: #6b6f82;
}

.carousel
{
    position: relative;
}

.carousel-inner
{
    position: relative;

    overflow: hidden; 

    width: 100%;
}

.carousel-item
{
    position: relative;

    display: none;

    width: 100%;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-backface-visibility: hidden;
       -moz-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-perspective: 1000px;
       -moz-perspective: 1000px;
            perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev
{
    display: block;

    -webkit-transition: -webkit-transform .6s ease;
       -moz-transition:         transform .6s ease, -moz-transform .6s ease;
         -o-transition:      -o-transform .6s ease;
            transition: -webkit-transform .6s ease;
            transition:         transform .6s ease;
            transition:         transform .6s ease, -webkit-transform .6s ease, -moz-transform .6s ease, -o-transform .6s ease;
}
@media screen and (prefers-reduced-motion: reduce)
{
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev
    {
        -webkit-transition: none;
           -moz-transition: none;
             -o-transition: none;
                transition: none;
    }
}

.carousel-item-next,
.carousel-item-prev
{
    position: absolute;
    top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right
{
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
        -ms-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d))
{
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right
    {
        -webkit-transform: translate3d(0, 0, 0);
           -moz-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

.carousel-item-next,
.active.carousel-item-right
{
    -webkit-transform: translateX(100%);
       -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
         -o-transform: translateX(100%);
            transform: translateX(100%);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d))
{
    .carousel-item-next,
    .active.carousel-item-right
    {
        -webkit-transform: translate3d(100%, 0, 0);
           -moz-transform: translate3d(100%, 0, 0);
                transform: translate3d(100%, 0, 0);
    }
}

.carousel-item-prev,
.active.carousel-item-left
{
    -webkit-transform: translateX(-100%);
       -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
         -o-transform: translateX(-100%);
            transform: translateX(-100%);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d))
{
    .carousel-item-prev,
    .active.carousel-item-left
    {
        -webkit-transform: translate3d(-100%, 0, 0);
           -moz-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }
}

.carousel-fade .carousel-item
{
    -webkit-transition-duration: .6s;
       -moz-transition-duration: .6s;
         -o-transition-duration: .6s;
            transition-duration: .6s;
    -webkit-transition-property: opacity;
       -moz-transition-property: opacity;
         -o-transition-property: opacity;
            transition-property: opacity; 

    opacity: 0;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right
{
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right
{
    opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev
{
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
        -ms-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d))
{
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev
    {
        -webkit-transform: translate3d(0, 0, 0);
           -moz-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

.carousel-control-prev,
.carousel-control-next
{
    position: absolute;
    top: 0;
    bottom: 0;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    width: 15%;

    text-align: center;

    opacity: .5; 
    color: #fff;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus
{
    text-decoration: none;

    opacity: .9; 
    color: #fff;
    outline: 0;
}

.carousel-control-prev
{
    left: 0;
}

.carousel-control-next
{
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon
{
    display: inline-block;

    width: 20px;
    height: 20px;

    background: transparent no-repeat center center;
    -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
}
.carousel-indicators
{
    position: absolute;
    z-index: 15;
    right: 0;
    bottom: 10px;
    left: 0;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-right: 15%;
    margin-left: 15%;
    padding-left: 0;

    list-style: none; 

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
}
.carousel-indicators li
{
    position: relative;

    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;

    cursor: pointer;
    text-indent: -999px;

    background-color: rgba(255, 255, 255, .5); 

    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
}
.carousel-indicators li::before
{
    position: absolute;
    top: -10px;
    left: 0;

    display: inline-block;

    width: 100%;
    height: 10px;

    content: '';
}
.carousel-indicators li::after
{
    position: absolute;
    bottom: -10px;
    left: 0;

    display: inline-block;

    width: 100%;
    height: 10px;

    content: '';
}
.carousel-indicators .active
{
    background-color: #fff;
}

.carousel-caption
{
    position: absolute;
    z-index: 10;
    right: 15%;
    bottom: 20px;
    left: 15%;

    padding-top: 20px;
    padding-bottom: 20px;

    text-align: center; 

    color: #fff;
}

.align-baseline
{
    vertical-align: baseline !important;
}

.align-top
{
    vertical-align: top !important;
}

.align-middle
{
    vertical-align: middle !important;
}

.align-bottom
{
    vertical-align: bottom !important;
}

.align-text-bottom
{
    vertical-align: text-bottom !important;
}

.align-text-top
{
    vertical-align: text-top !important;
}

.bg-primary
{
    background-color: #666ee8 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus
{
    background-color: #3a44e1 !important;
}

.bg-secondary
{
    background-color: #6b6f82 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus
{
    background-color: #545766 !important;
}

.bg-success
{
    background-color: #28d094 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus
{
    background-color: #20a576 !important;
}

.bg-info
{
    background-color: #1e9ff2 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus
{
    background-color: #0c84d1 !important;
}

.bg-warning
{
    background-color: #ff9149 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus
{
    background-color: #ff7216 !important;
}

.bg-danger
{
    background-color: #ff4961 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus
{
    background-color: #ff1635 !important;
}

.bg-light
{
    background-color: #babfc7 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus
{
    background-color: #9ea5b0 !important;
}

.bg-dark
{
    background-color: #464855 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus
{
    background-color: #2f3039 !important;
}

.bg-white
{
    background-color: #fff !important;
}

.bg-transparent
{
    background-color: transparent !important;
}

.border
{
    border: 1px solid #626e82 !important;
}

.border-top
{
    border-top: 1px solid #626e82 !important;
}

.border-right
{
    border-right: 1px solid #626e82 !important;
}

.border-bottom
{
    border-bottom: 1px solid #626e82 !important;
}

.border-left
{
    border-left: 1px solid #626e82 !important;
}

.border-0
{
    border: 0 !important;
}

.border-top-0
{
    border-top: 0 !important;
}

.border-right-0
{
    border-right: 0 !important;
}

.border-bottom-0
{
    border-bottom: 0 !important;
}

.border-left-0
{
    border-left: 0 !important;
}

.border-primary
{
    border-color: #666ee8 !important;
}

.border-secondary
{
    border-color: #6b6f82 !important;
}

.border-success
{
    border-color: #28d094 !important;
}

.border-info
{
    border-color: #1e9ff2 !important;
}

.border-warning
{
    border-color: #ff9149 !important;
}

.border-danger
{
    border-color: #ff4961 !important;
}

.border-light
{
    border-color: #babfc7 !important;
}

.border-dark
{
    border-color: #464855 !important;
}

.border-white
{
    border-color: #fff !important;
}

.rounded
{
    border-radius: .25rem !important;
}

.rounded-top
{
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important;
}

.rounded-right
{
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
}

.rounded-bottom
{
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
}

.rounded-left
{
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important;
}

.rounded-circle
{
    border-radius: 50% !important;
}

.rounded-0
{
    border-radius: 0 !important;
}

.clearfix::after
{
    display: block;
    clear: both;

    content: '';
}

.d-none
{
    display: none !important;
}

.d-inline
{
    display: inline !important;
}

.d-inline-block
{
    display: inline-block !important;
}

.d-block
{
    display: block !important;
}

.d-table
{
    display: table !important;
}

.d-table-row
{
    display: table-row !important;
}

.d-table-cell
{
    display: table-cell !important;
}

.d-flex
{
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display:    -moz-box !important;
    display: -ms-flexbox !important;
    display:         flex !important;
}

.d-inline-flex
{
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display:    -moz-inline-box !important;
    display: -ms-inline-flexbox !important;
    display:         inline-flex !important;
}

@media (min-width: 576px)
{
    .d-sm-none
    {
        display: none !important;
    }
    .d-sm-inline
    {
        display: inline !important;
    }
    .d-sm-inline-block
    {
        display: inline-block !important;
    }
    .d-sm-block
    {
        display: block !important;
    }
    .d-sm-table
    {
        display: table !important;
    }
    .d-sm-table-row
    {
        display: table-row !important;
    }
    .d-sm-table-cell
    {
        display: table-cell !important;
    }
    .d-sm-flex
    {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display:    -moz-box !important;
        display: -ms-flexbox !important;
        display:         flex !important;
    }
    .d-sm-inline-flex
    {
        display: -webkit-inline-box !important;
        display: -webkit-inline-flex !important;
        display:    -moz-inline-box !important;
        display: -ms-inline-flexbox !important;
        display:         inline-flex !important;
    }
}

@media (min-width: 768px)
{
    .d-md-none
    {
        display: none !important;
    }
    .d-md-inline
    {
        display: inline !important;
    }
    .d-md-inline-block
    {
        display: inline-block !important;
    }
    .d-md-block
    {
        display: block !important;
    }
    .d-md-table
    {
        display: table !important;
    }
    .d-md-table-row
    {
        display: table-row !important;
    }
    .d-md-table-cell
    {
        display: table-cell !important;
    }
    .d-md-flex
    {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display:    -moz-box !important;
        display: -ms-flexbox !important;
        display:         flex !important;
    }
    .d-md-inline-flex
    {
        display: -webkit-inline-box !important;
        display: -webkit-inline-flex !important;
        display:    -moz-inline-box !important;
        display: -ms-inline-flexbox !important;
        display:         inline-flex !important;
    }
}

@media (min-width: 992px)
{
    .d-lg-none
    {
        display: none !important;
    }
    .d-lg-inline
    {
        display: inline !important;
    }
    .d-lg-inline-block
    {
        display: inline-block !important;
    }
    .d-lg-block
    {
        display: block !important;
    }
    .d-lg-table
    {
        display: table !important;
    }
    .d-lg-table-row
    {
        display: table-row !important;
    }
    .d-lg-table-cell
    {
        display: table-cell !important;
    }
    .d-lg-flex
    {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display:    -moz-box !important;
        display: -ms-flexbox !important;
        display:         flex !important;
    }
    .d-lg-inline-flex
    {
        display: -webkit-inline-box !important;
        display: -webkit-inline-flex !important;
        display:    -moz-inline-box !important;
        display: -ms-inline-flexbox !important;
        display:         inline-flex !important;
    }
}

@media (min-width: 1200px)
{
    .d-xl-none
    {
        display: none !important;
    }
    .d-xl-inline
    {
        display: inline !important;
    }
    .d-xl-inline-block
    {
        display: inline-block !important;
    }
    .d-xl-block
    {
        display: block !important;
    }
    .d-xl-table
    {
        display: table !important;
    }
    .d-xl-table-row
    {
        display: table-row !important;
    }
    .d-xl-table-cell
    {
        display: table-cell !important;
    }
    .d-xl-flex
    {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display:    -moz-box !important;
        display: -ms-flexbox !important;
        display:         flex !important;
    }
    .d-xl-inline-flex
    {
        display: -webkit-inline-box !important;
        display: -webkit-inline-flex !important;
        display:    -moz-inline-box !important;
        display: -ms-inline-flexbox !important;
        display:         inline-flex !important;
    }
}

@media print
{
    .d-print-none
    {
        display: none !important;
    }
    .d-print-inline
    {
        display: inline !important;
    }
    .d-print-inline-block
    {
        display: inline-block !important;
    }
    .d-print-block
    {
        display: block !important;
    }
    .d-print-table
    {
        display: table !important;
    }
    .d-print-table-row
    {
        display: table-row !important;
    }
    .d-print-table-cell
    {
        display: table-cell !important;
    }
    .d-print-flex
    {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display:    -moz-box !important;
        display: -ms-flexbox !important;
        display:         flex !important;
    }
    .d-print-inline-flex
    {
        display: -webkit-inline-box !important;
        display: -webkit-inline-flex !important;
        display:    -moz-inline-box !important;
        display: -ms-inline-flexbox !important;
        display:         inline-flex !important;
    }
}

.embed-responsive
{
    position: relative;

    display: block;
    overflow: hidden; 

    width: 100%;
    padding: 0;
}
.embed-responsive::before
{
    display: block;

    content: '';
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video
{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: 0;
}

.embed-responsive-21by9::before
{
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before
{
    padding-top: 56.25%;
}

.embed-responsive-4by3::before
{
    padding-top: 75%;
}

.embed-responsive-1by1::before
{
    padding-top: 100%;
}

.flex-row
{
            flex-direction: row !important; 

    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
       -moz-box-orient: horizontal !important;
       -moz-box-direction: normal !important;
        -ms-flex-direction: row !important;
}

.flex-column
{
            flex-direction: column !important; 

    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
       -moz-box-orient: vertical !important;
       -moz-box-direction: normal !important;
        -ms-flex-direction: column !important;
}

.flex-row-reverse
{
            flex-direction: row-reverse !important; 

    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
       -moz-box-orient: horizontal !important;
       -moz-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
}

.flex-column-reverse
{
            flex-direction: column-reverse !important; 

    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
       -moz-box-orient: vertical !important;
       -moz-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
}

.flex-wrap
{
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
}

.flex-nowrap
{
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
}

.flex-wrap-reverse
{
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
}

.flex-fill
{
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
       -moz-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
}

.flex-grow-0
{
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
       -moz-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
}

.flex-grow-1
{
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
       -moz-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
}

.flex-shrink-0
{
    -webkit-flex-shrink: 0 !important;
    -ms-flex-negative: 0 !important;
            flex-shrink: 0 !important;
}

.flex-shrink-1
{
    -webkit-flex-shrink: 1 !important;
    -ms-flex-negative: 1 !important;
            flex-shrink: 1 !important;
}

.justify-content-start
{
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
       -moz-box-pack: start !important;
    -ms-flex-pack: start !important;
            justify-content: flex-start !important;
}

.justify-content-end
{
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
       -moz-box-pack: end !important;
    -ms-flex-pack: end !important;
            justify-content: flex-end !important;
}

.justify-content-center
{
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
       -moz-box-pack: center !important;
    -ms-flex-pack: center !important;
            justify-content: center !important;
}

.justify-content-between
{
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
       -moz-box-pack: justify !important;
    -ms-flex-pack: justify !important;
            justify-content: space-between !important;
}

.justify-content-around
{
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: distribute !important;
            justify-content: space-around !important;
}

.align-items-start
{
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
       -moz-box-align: start !important;
    -ms-flex-align: start !important;
            align-items: flex-start !important;
}

.align-items-end
{
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
       -moz-box-align: end !important;
    -ms-flex-align: end !important;
            align-items: flex-end !important;
}

.align-items-center
{
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
       -moz-box-align: center !important;
    -ms-flex-align: center !important;
            align-items: center !important;
}

.align-items-baseline
{
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
       -moz-box-align: baseline !important;
    -ms-flex-align: baseline !important;
            align-items: baseline !important;
}

.align-items-stretch
{
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
       -moz-box-align: stretch !important;
    -ms-flex-align: stretch !important;
            align-items: stretch !important;
}

.align-content-start
{
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
            align-content: flex-start !important;
}

.align-content-end
{
    -webkit-align-content: flex-end !important;
    -ms-flex-line-pack: end !important;
            align-content: flex-end !important;
}

.align-content-center
{
    -webkit-align-content: center !important;
    -ms-flex-line-pack: center !important;
            align-content: center !important;
}

.align-content-between
{
    -webkit-align-content: space-between !important;
    -ms-flex-line-pack: justify !important;
            align-content: space-between !important;
}

.align-content-around
{
    -webkit-align-content: space-around !important;
    -ms-flex-line-pack: distribute !important;
            align-content: space-around !important;
}

.align-content-stretch
{
    -webkit-align-content: stretch !important;
    -ms-flex-line-pack: stretch !important;
            align-content: stretch !important;
}

.align-self-auto
{
    -webkit-align-self: auto !important;
    -ms-flex-item-align: auto !important;
            align-self: auto !important;
}

.align-self-start
{
    -webkit-align-self: flex-start !important;
    -ms-flex-item-align: start !important;
            align-self: flex-start !important;
}

.align-self-end
{
    -webkit-align-self: flex-end !important;
    -ms-flex-item-align: end !important;
            align-self: flex-end !important;
}

.align-self-center
{
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
            align-self: center !important;
}

.align-self-baseline
{
    -webkit-align-self: baseline !important;
    -ms-flex-item-align: baseline !important;
            align-self: baseline !important;
}

.align-self-stretch
{
    -webkit-align-self: stretch !important;
    -ms-flex-item-align: stretch !important;
            align-self: stretch !important;
}

@media (min-width: 576px)
{
    .flex-sm-row
    {
                flex-direction: row !important; 

        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: row !important;
           -moz-box-orient: horizontal !important;
           -moz-box-direction: normal !important;
            -ms-flex-direction: row !important;
    }
    .flex-sm-column
    {
                flex-direction: column !important; 

        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: column !important;
           -moz-box-orient: vertical !important;
           -moz-box-direction: normal !important;
            -ms-flex-direction: column !important;
    }
    .flex-sm-row-reverse
    {
                flex-direction: row-reverse !important; 

        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: row-reverse !important;
           -moz-box-orient: horizontal !important;
           -moz-box-direction: reverse !important;
            -ms-flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse
    {
                flex-direction: column-reverse !important; 

        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: column-reverse !important;
           -moz-box-orient: vertical !important;
           -moz-box-direction: reverse !important;
            -ms-flex-direction: column-reverse !important;
    }
    .flex-sm-wrap
    {
        -webkit-flex-wrap: wrap !important;
            -ms-flex-wrap: wrap !important;
                flex-wrap: wrap !important;
    }
    .flex-sm-nowrap
    {
        -webkit-flex-wrap: nowrap !important;
            -ms-flex-wrap: nowrap !important;
                flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse
    {
        -webkit-flex-wrap: wrap-reverse !important;
            -ms-flex-wrap: wrap-reverse !important;
                flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill
    {
        -webkit-box-flex: 1 !important;
        -webkit-flex: 1 1 auto !important;
           -moz-box-flex: 1 !important;
            -ms-flex: 1 1 auto !important;
                flex: 1 1 auto !important;
    }
    .flex-sm-grow-0
    {
        -webkit-box-flex: 0 !important;
        -webkit-flex-grow: 0 !important;
           -moz-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
                flex-grow: 0 !important;
    }
    .flex-sm-grow-1
    {
        -webkit-box-flex: 1 !important;
        -webkit-flex-grow: 1 !important;
           -moz-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
                flex-grow: 1 !important;
    }
    .flex-sm-shrink-0
    {
        -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
                flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1
    {
        -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
                flex-shrink: 1 !important;
    }
    .justify-content-sm-start
    {
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
           -moz-box-pack: start !important;
        -ms-flex-pack: start !important;
                justify-content: flex-start !important;
    }
    .justify-content-sm-end
    {
        -webkit-box-pack: end !important;
        -webkit-justify-content: flex-end !important;
           -moz-box-pack: end !important;
        -ms-flex-pack: end !important;
                justify-content: flex-end !important;
    }
    .justify-content-sm-center
    {
        -webkit-box-pack: center !important;
        -webkit-justify-content: center !important;
           -moz-box-pack: center !important;
        -ms-flex-pack: center !important;
                justify-content: center !important;
    }
    .justify-content-sm-between
    {
        -webkit-box-pack: justify !important;
        -webkit-justify-content: space-between !important;
           -moz-box-pack: justify !important;
        -ms-flex-pack: justify !important;
                justify-content: space-between !important;
    }
    .justify-content-sm-around
    {
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
                justify-content: space-around !important;
    }
    .align-items-sm-start
    {
        -webkit-box-align: start !important;
        -webkit-align-items: flex-start !important;
           -moz-box-align: start !important;
        -ms-flex-align: start !important;
                align-items: flex-start !important;
    }
    .align-items-sm-end
    {
        -webkit-box-align: end !important;
        -webkit-align-items: flex-end !important;
           -moz-box-align: end !important;
        -ms-flex-align: end !important;
                align-items: flex-end !important;
    }
    .align-items-sm-center
    {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
           -moz-box-align: center !important;
        -ms-flex-align: center !important;
                align-items: center !important;
    }
    .align-items-sm-baseline
    {
        -webkit-box-align: baseline !important;
        -webkit-align-items: baseline !important;
           -moz-box-align: baseline !important;
        -ms-flex-align: baseline !important;
                align-items: baseline !important;
    }
    .align-items-sm-stretch
    {
        -webkit-box-align: stretch !important;
        -webkit-align-items: stretch !important;
           -moz-box-align: stretch !important;
        -ms-flex-align: stretch !important;
                align-items: stretch !important;
    }
    .align-content-sm-start
    {
        -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
                align-content: flex-start !important;
    }
    .align-content-sm-end
    {
        -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
                align-content: flex-end !important;
    }
    .align-content-sm-center
    {
        -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
                align-content: center !important;
    }
    .align-content-sm-between
    {
        -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
                align-content: space-between !important;
    }
    .align-content-sm-around
    {
        -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
                align-content: space-around !important;
    }
    .align-content-sm-stretch
    {
        -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
                align-content: stretch !important;
    }
    .align-self-sm-auto
    {
        -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
                align-self: auto !important;
    }
    .align-self-sm-start
    {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
                align-self: flex-start !important;
    }
    .align-self-sm-end
    {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
                align-self: flex-end !important;
    }
    .align-self-sm-center
    {
        -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
                align-self: center !important;
    }
    .align-self-sm-baseline
    {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
                align-self: baseline !important;
    }
    .align-self-sm-stretch
    {
        -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
                align-self: stretch !important;
    }
}

@media (min-width: 768px)
{
    .flex-md-row
    {
                flex-direction: row !important; 

        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: row !important;
           -moz-box-orient: horizontal !important;
           -moz-box-direction: normal !important;
            -ms-flex-direction: row !important;
    }
    .flex-md-column
    {
                flex-direction: column !important; 

        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: column !important;
           -moz-box-orient: vertical !important;
           -moz-box-direction: normal !important;
            -ms-flex-direction: column !important;
    }
    .flex-md-row-reverse
    {
                flex-direction: row-reverse !important; 

        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: row-reverse !important;
           -moz-box-orient: horizontal !important;
           -moz-box-direction: reverse !important;
            -ms-flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse
    {
                flex-direction: column-reverse !important; 

        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: column-reverse !important;
           -moz-box-orient: vertical !important;
           -moz-box-direction: reverse !important;
            -ms-flex-direction: column-reverse !important;
    }
    .flex-md-wrap
    {
        -webkit-flex-wrap: wrap !important;
            -ms-flex-wrap: wrap !important;
                flex-wrap: wrap !important;
    }
    .flex-md-nowrap
    {
        -webkit-flex-wrap: nowrap !important;
            -ms-flex-wrap: nowrap !important;
                flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse
    {
        -webkit-flex-wrap: wrap-reverse !important;
            -ms-flex-wrap: wrap-reverse !important;
                flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill
    {
        -webkit-box-flex: 1 !important;
        -webkit-flex: 1 1 auto !important;
           -moz-box-flex: 1 !important;
            -ms-flex: 1 1 auto !important;
                flex: 1 1 auto !important;
    }
    .flex-md-grow-0
    {
        -webkit-box-flex: 0 !important;
        -webkit-flex-grow: 0 !important;
           -moz-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
                flex-grow: 0 !important;
    }
    .flex-md-grow-1
    {
        -webkit-box-flex: 1 !important;
        -webkit-flex-grow: 1 !important;
           -moz-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
                flex-grow: 1 !important;
    }
    .flex-md-shrink-0
    {
        -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
                flex-shrink: 0 !important;
    }
    .flex-md-shrink-1
    {
        -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
                flex-shrink: 1 !important;
    }
    .justify-content-md-start
    {
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
           -moz-box-pack: start !important;
        -ms-flex-pack: start !important;
                justify-content: flex-start !important;
    }
    .justify-content-md-end
    {
        -webkit-box-pack: end !important;
        -webkit-justify-content: flex-end !important;
           -moz-box-pack: end !important;
        -ms-flex-pack: end !important;
                justify-content: flex-end !important;
    }
    .justify-content-md-center
    {
        -webkit-box-pack: center !important;
        -webkit-justify-content: center !important;
           -moz-box-pack: center !important;
        -ms-flex-pack: center !important;
                justify-content: center !important;
    }
    .justify-content-md-between
    {
        -webkit-box-pack: justify !important;
        -webkit-justify-content: space-between !important;
           -moz-box-pack: justify !important;
        -ms-flex-pack: justify !important;
                justify-content: space-between !important;
    }
    .justify-content-md-around
    {
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
                justify-content: space-around !important;
    }
    .align-items-md-start
    {
        -webkit-box-align: start !important;
        -webkit-align-items: flex-start !important;
           -moz-box-align: start !important;
        -ms-flex-align: start !important;
                align-items: flex-start !important;
    }
    .align-items-md-end
    {
        -webkit-box-align: end !important;
        -webkit-align-items: flex-end !important;
           -moz-box-align: end !important;
        -ms-flex-align: end !important;
                align-items: flex-end !important;
    }
    .align-items-md-center
    {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
           -moz-box-align: center !important;
        -ms-flex-align: center !important;
                align-items: center !important;
    }
    .align-items-md-baseline
    {
        -webkit-box-align: baseline !important;
        -webkit-align-items: baseline !important;
           -moz-box-align: baseline !important;
        -ms-flex-align: baseline !important;
                align-items: baseline !important;
    }
    .align-items-md-stretch
    {
        -webkit-box-align: stretch !important;
        -webkit-align-items: stretch !important;
           -moz-box-align: stretch !important;
        -ms-flex-align: stretch !important;
                align-items: stretch !important;
    }
    .align-content-md-start
    {
        -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
                align-content: flex-start !important;
    }
    .align-content-md-end
    {
        -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
                align-content: flex-end !important;
    }
    .align-content-md-center
    {
        -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
                align-content: center !important;
    }
    .align-content-md-between
    {
        -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
                align-content: space-between !important;
    }
    .align-content-md-around
    {
        -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
                align-content: space-around !important;
    }
    .align-content-md-stretch
    {
        -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
                align-content: stretch !important;
    }
    .align-self-md-auto
    {
        -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
                align-self: auto !important;
    }
    .align-self-md-start
    {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
                align-self: flex-start !important;
    }
    .align-self-md-end
    {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
                align-self: flex-end !important;
    }
    .align-self-md-center
    {
        -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
                align-self: center !important;
    }
    .align-self-md-baseline
    {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
                align-self: baseline !important;
    }
    .align-self-md-stretch
    {
        -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
                align-self: stretch !important;
    }
}

@media (min-width: 992px)
{
    .flex-lg-row
    {
                flex-direction: row !important; 

        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: row !important;
           -moz-box-orient: horizontal !important;
           -moz-box-direction: normal !important;
            -ms-flex-direction: row !important;
    }
    .flex-lg-column
    {
                flex-direction: column !important; 

        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: column !important;
           -moz-box-orient: vertical !important;
           -moz-box-direction: normal !important;
            -ms-flex-direction: column !important;
    }
    .flex-lg-row-reverse
    {
                flex-direction: row-reverse !important; 

        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: row-reverse !important;
           -moz-box-orient: horizontal !important;
           -moz-box-direction: reverse !important;
            -ms-flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse
    {
                flex-direction: column-reverse !important; 

        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: column-reverse !important;
           -moz-box-orient: vertical !important;
           -moz-box-direction: reverse !important;
            -ms-flex-direction: column-reverse !important;
    }
    .flex-lg-wrap
    {
        -webkit-flex-wrap: wrap !important;
            -ms-flex-wrap: wrap !important;
                flex-wrap: wrap !important;
    }
    .flex-lg-nowrap
    {
        -webkit-flex-wrap: nowrap !important;
            -ms-flex-wrap: nowrap !important;
                flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse
    {
        -webkit-flex-wrap: wrap-reverse !important;
            -ms-flex-wrap: wrap-reverse !important;
                flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill
    {
        -webkit-box-flex: 1 !important;
        -webkit-flex: 1 1 auto !important;
           -moz-box-flex: 1 !important;
            -ms-flex: 1 1 auto !important;
                flex: 1 1 auto !important;
    }
    .flex-lg-grow-0
    {
        -webkit-box-flex: 0 !important;
        -webkit-flex-grow: 0 !important;
           -moz-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
                flex-grow: 0 !important;
    }
    .flex-lg-grow-1
    {
        -webkit-box-flex: 1 !important;
        -webkit-flex-grow: 1 !important;
           -moz-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
                flex-grow: 1 !important;
    }
    .flex-lg-shrink-0
    {
        -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
                flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1
    {
        -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
                flex-shrink: 1 !important;
    }
    .justify-content-lg-start
    {
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
           -moz-box-pack: start !important;
        -ms-flex-pack: start !important;
                justify-content: flex-start !important;
    }
    .justify-content-lg-end
    {
        -webkit-box-pack: end !important;
        -webkit-justify-content: flex-end !important;
           -moz-box-pack: end !important;
        -ms-flex-pack: end !important;
                justify-content: flex-end !important;
    }
    .justify-content-lg-center
    {
        -webkit-box-pack: center !important;
        -webkit-justify-content: center !important;
           -moz-box-pack: center !important;
        -ms-flex-pack: center !important;
                justify-content: center !important;
    }
    .justify-content-lg-between
    {
        -webkit-box-pack: justify !important;
        -webkit-justify-content: space-between !important;
           -moz-box-pack: justify !important;
        -ms-flex-pack: justify !important;
                justify-content: space-between !important;
    }
    .justify-content-lg-around
    {
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
                justify-content: space-around !important;
    }
    .align-items-lg-start
    {
        -webkit-box-align: start !important;
        -webkit-align-items: flex-start !important;
           -moz-box-align: start !important;
        -ms-flex-align: start !important;
                align-items: flex-start !important;
    }
    .align-items-lg-end
    {
        -webkit-box-align: end !important;
        -webkit-align-items: flex-end !important;
           -moz-box-align: end !important;
        -ms-flex-align: end !important;
                align-items: flex-end !important;
    }
    .align-items-lg-center
    {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
           -moz-box-align: center !important;
        -ms-flex-align: center !important;
                align-items: center !important;
    }
    .align-items-lg-baseline
    {
        -webkit-box-align: baseline !important;
        -webkit-align-items: baseline !important;
           -moz-box-align: baseline !important;
        -ms-flex-align: baseline !important;
                align-items: baseline !important;
    }
    .align-items-lg-stretch
    {
        -webkit-box-align: stretch !important;
        -webkit-align-items: stretch !important;
           -moz-box-align: stretch !important;
        -ms-flex-align: stretch !important;
                align-items: stretch !important;
    }
    .align-content-lg-start
    {
        -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
                align-content: flex-start !important;
    }
    .align-content-lg-end
    {
        -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
                align-content: flex-end !important;
    }
    .align-content-lg-center
    {
        -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
                align-content: center !important;
    }
    .align-content-lg-between
    {
        -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
                align-content: space-between !important;
    }
    .align-content-lg-around
    {
        -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
                align-content: space-around !important;
    }
    .align-content-lg-stretch
    {
        -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
                align-content: stretch !important;
    }
    .align-self-lg-auto
    {
        -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
                align-self: auto !important;
    }
    .align-self-lg-start
    {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
                align-self: flex-start !important;
    }
    .align-self-lg-end
    {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
                align-self: flex-end !important;
    }
    .align-self-lg-center
    {
        -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
                align-self: center !important;
    }
    .align-self-lg-baseline
    {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
                align-self: baseline !important;
    }
    .align-self-lg-stretch
    {
        -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
                align-self: stretch !important;
    }
}

@media (min-width: 1200px)
{
    .flex-xl-row
    {
                flex-direction: row !important; 

        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: row !important;
           -moz-box-orient: horizontal !important;
           -moz-box-direction: normal !important;
            -ms-flex-direction: row !important;
    }
    .flex-xl-column
    {
                flex-direction: column !important; 

        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: column !important;
           -moz-box-orient: vertical !important;
           -moz-box-direction: normal !important;
            -ms-flex-direction: column !important;
    }
    .flex-xl-row-reverse
    {
                flex-direction: row-reverse !important; 

        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: row-reverse !important;
           -moz-box-orient: horizontal !important;
           -moz-box-direction: reverse !important;
            -ms-flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse
    {
                flex-direction: column-reverse !important; 

        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: column-reverse !important;
           -moz-box-orient: vertical !important;
           -moz-box-direction: reverse !important;
            -ms-flex-direction: column-reverse !important;
    }
    .flex-xl-wrap
    {
        -webkit-flex-wrap: wrap !important;
            -ms-flex-wrap: wrap !important;
                flex-wrap: wrap !important;
    }
    .flex-xl-nowrap
    {
        -webkit-flex-wrap: nowrap !important;
            -ms-flex-wrap: nowrap !important;
                flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse
    {
        -webkit-flex-wrap: wrap-reverse !important;
            -ms-flex-wrap: wrap-reverse !important;
                flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill
    {
        -webkit-box-flex: 1 !important;
        -webkit-flex: 1 1 auto !important;
           -moz-box-flex: 1 !important;
            -ms-flex: 1 1 auto !important;
                flex: 1 1 auto !important;
    }
    .flex-xl-grow-0
    {
        -webkit-box-flex: 0 !important;
        -webkit-flex-grow: 0 !important;
           -moz-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
                flex-grow: 0 !important;
    }
    .flex-xl-grow-1
    {
        -webkit-box-flex: 1 !important;
        -webkit-flex-grow: 1 !important;
           -moz-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
                flex-grow: 1 !important;
    }
    .flex-xl-shrink-0
    {
        -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
                flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1
    {
        -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
                flex-shrink: 1 !important;
    }
    .justify-content-xl-start
    {
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
           -moz-box-pack: start !important;
        -ms-flex-pack: start !important;
                justify-content: flex-start !important;
    }
    .justify-content-xl-end
    {
        -webkit-box-pack: end !important;
        -webkit-justify-content: flex-end !important;
           -moz-box-pack: end !important;
        -ms-flex-pack: end !important;
                justify-content: flex-end !important;
    }
    .justify-content-xl-center
    {
        -webkit-box-pack: center !important;
        -webkit-justify-content: center !important;
           -moz-box-pack: center !important;
        -ms-flex-pack: center !important;
                justify-content: center !important;
    }
    .justify-content-xl-between
    {
        -webkit-box-pack: justify !important;
        -webkit-justify-content: space-between !important;
           -moz-box-pack: justify !important;
        -ms-flex-pack: justify !important;
                justify-content: space-between !important;
    }
    .justify-content-xl-around
    {
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
                justify-content: space-around !important;
    }
    .align-items-xl-start
    {
        -webkit-box-align: start !important;
        -webkit-align-items: flex-start !important;
           -moz-box-align: start !important;
        -ms-flex-align: start !important;
                align-items: flex-start !important;
    }
    .align-items-xl-end
    {
        -webkit-box-align: end !important;
        -webkit-align-items: flex-end !important;
           -moz-box-align: end !important;
        -ms-flex-align: end !important;
                align-items: flex-end !important;
    }
    .align-items-xl-center
    {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
           -moz-box-align: center !important;
        -ms-flex-align: center !important;
                align-items: center !important;
    }
    .align-items-xl-baseline
    {
        -webkit-box-align: baseline !important;
        -webkit-align-items: baseline !important;
           -moz-box-align: baseline !important;
        -ms-flex-align: baseline !important;
                align-items: baseline !important;
    }
    .align-items-xl-stretch
    {
        -webkit-box-align: stretch !important;
        -webkit-align-items: stretch !important;
           -moz-box-align: stretch !important;
        -ms-flex-align: stretch !important;
                align-items: stretch !important;
    }
    .align-content-xl-start
    {
        -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
                align-content: flex-start !important;
    }
    .align-content-xl-end
    {
        -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
                align-content: flex-end !important;
    }
    .align-content-xl-center
    {
        -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
                align-content: center !important;
    }
    .align-content-xl-between
    {
        -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
                align-content: space-between !important;
    }
    .align-content-xl-around
    {
        -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
                align-content: space-around !important;
    }
    .align-content-xl-stretch
    {
        -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
                align-content: stretch !important;
    }
    .align-self-xl-auto
    {
        -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
                align-self: auto !important;
    }
    .align-self-xl-start
    {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
                align-self: flex-start !important;
    }
    .align-self-xl-end
    {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
                align-self: flex-end !important;
    }
    .align-self-xl-center
    {
        -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
                align-self: center !important;
    }
    .align-self-xl-baseline
    {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
                align-self: baseline !important;
    }
    .align-self-xl-stretch
    {
        -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
                align-self: stretch !important;
    }
}

.float-left
{
    float: left !important;
}

.float-right
{
    float: right !important;
}

.float-none
{
    float: none !important;
}

@media (min-width: 576px)
{
    .float-sm-left
    {
        float: left !important;
    }
    .float-sm-right
    {
        float: right !important;
    }
    .float-sm-none
    {
        float: none !important;
    }
}

@media (min-width: 768px)
{
    .float-md-left
    {
        float: left !important;
    }
    .float-md-right
    {
        float: right !important;
    }
    .float-md-none
    {
        float: none !important;
    }
}

@media (min-width: 992px)
{
    .float-lg-left
    {
        float: left !important;
    }
    .float-lg-right
    {
        float: right !important;
    }
    .float-lg-none
    {
        float: none !important;
    }
}

@media (min-width: 1200px)
{
    .float-xl-left
    {
        float: left !important;
    }
    .float-xl-right
    {
        float: right !important;
    }
    .float-xl-none
    {
        float: none !important;
    }
}

.position-static
{
    position: static !important;
}

.position-relative
{
    position: relative !important;
}

.position-absolute
{
    position: absolute !important;
}

.position-fixed
{
    position: fixed !important;
}

.position-sticky
{
    position: -webkit-sticky !important;
    position:         sticky !important;
}

.fixed-top
{
    position: fixed;
    z-index: 1030; 
    top: 0;
    right: 0;
    left: 0;
}

.fixed-bottom
{
    position: fixed;
    z-index: 1030; 
    right: 0;
    bottom: 0;
    left: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky))
{
    .sticky-top
    {
        position: -webkit-sticky;
        position:         sticky;
        z-index: 1020; 
        top: 0;
    }
}

.sr-only
{
    position: absolute;

    overflow: hidden;
    clip: rect(0, 0, 0, 0);

    width: 1px;
    height: 1px;
    padding: 0;

    white-space: nowrap;

    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus
{
    position: static;

    overflow: visible;
    clip: auto;

    width: auto;
    height: auto;

    white-space: normal;
}

.shadow-sm
{
    -webkit-box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
            box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.shadow
{
    -webkit-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.shadow-lg
{
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.shadow-none
{
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.w-25
{
    width: 25% !important;
}

.w-50
{
    width: 50% !important;
}

.w-75
{
    width: 75% !important;
}

.w-100
{
    width: 100% !important;
}

.w-auto
{
    width: auto !important;
}

.h-25
{
    height: 25% !important;
}

.h-50
{
    height: 50% !important;
}

.h-75
{
    height: 75% !important;
}

.h-100
{
    height: 100% !important;
}

.h-auto
{
    height: auto !important;
}

.mw-100
{
    max-width: 100% !important;
}

.mh-100
{
    max-height: 100% !important;
}

.m-0
{
    margin: 0 !important;
}

.mt-0,
.my-0
{
    margin-top: 0 !important;
}

.mr-0,
.mx-0
{
    margin-right: 0 !important;
}

.mb-0,
.my-0
{
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0
{
    margin-left: 0 !important;
}

.m-1
{
    margin: 1rem !important;
}

.mt-1,
.my-1
{
    margin-top: 1rem !important;
}

.mr-1,
.mx-1
{
    margin-right: 1rem !important;
}

.mb-1,
.my-1
{
    margin-bottom: 1rem !important;
}

.ml-1,
.mx-1
{
    margin-left: 1rem !important;
}

.m-2
{
    margin: 1.5rem !important;
}

.mt-2,
.my-2
{
    margin-top: 1.5rem !important;
}

.mr-2,
.mx-2
{
    margin-right: 1.5rem !important;
}

.mb-2,
.my-2
{
    margin-bottom: 1.5rem !important;
}

.ml-2,
.mx-2
{
    margin-left: 1.5rem !important;
}

.m-3
{
    margin: 3rem !important;
}

.mt-3,
.my-3
{
    margin-top: 3rem !important;
}

.mr-3,
.mx-3
{
    margin-right: 3rem !important;
}

.mb-3,
.my-3
{
    margin-bottom: 3rem !important;
}

.ml-3,
.mx-3
{
    margin-left: 3rem !important;
}

.m-4
{
    margin: 3.5rem !important;
}

.mt-4,
.my-4
{
    margin-top: 3.5rem !important;
}

.mr-4,
.mx-4
{
    margin-right: 3.5rem !important;
}

.mb-4,
.my-4
{
    margin-bottom: 3.5rem !important;
}

.ml-4,
.mx-4
{
    margin-left: 3.5rem !important;
}

.m-5
{
    margin: 4rem !important;
}

.mt-5,
.my-5
{
    margin-top: 4rem !important;
}

.mr-5,
.mx-5
{
    margin-right: 4rem !important;
}

.mb-5,
.my-5
{
    margin-bottom: 4rem !important;
}

.ml-5,
.mx-5
{
    margin-left: 4rem !important;
}

.p-0
{
    padding: 0 !important;
}

.pt-0,
.py-0
{
    padding-top: 0 !important;
}

.pr-0,
.px-0
{
    padding-right: 0 !important;
}

.pb-0,
.py-0
{
    padding-bottom: 0 !important;
}

.pl-0,
.px-0
{
    padding-left: 0 !important;
}

.p-1
{
    padding: 1rem !important;
}

.pt-1,
.py-1
{
    padding-top: 1rem !important;
}

.pr-1,
.px-1
{
    padding-right: 1rem !important;
}

.pb-1,
.py-1
{
    padding-bottom: 1rem !important;
}

.pl-1,
.px-1
{
    padding-left: 1rem !important;
}

.p-2
{
    padding: 1.5rem !important;
}

.pt-2,
.py-2
{
    padding-top: 1.5rem !important;
}

.pr-2,
.px-2
{
    padding-right: 1.5rem !important;
}

.pb-2,
.py-2
{
    padding-bottom: 1.5rem !important;
}

.pl-2,
.px-2
{
    padding-left: 1.5rem !important;
}

.p-3
{
    padding: 3rem !important;
}

.pt-3,
.py-3
{
    padding-top: 3rem !important;
}

.pr-3,
.px-3
{
    padding-right: 3rem !important;
}

.pb-3,
.py-3
{
    padding-bottom: 3rem !important;
}

.pl-3,
.px-3
{
    padding-left: 3rem !important;
}

.p-4
{
    padding: 3.5rem !important;
}

.pt-4,
.py-4
{
    padding-top: 3.5rem !important;
}

.pr-4,
.px-4
{
    padding-right: 3.5rem !important;
}

.pb-4,
.py-4
{
    padding-bottom: 3.5rem !important;
}

.pl-4,
.px-4
{
    padding-left: 3.5rem !important;
}

.p-5
{
    padding: 4rem !important;
}

.pt-5,
.py-5
{
    padding-top: 4rem !important;
}

.pr-5,
.px-5
{
    padding-right: 4rem !important;
}

.pb-5,
.py-5
{
    padding-bottom: 4rem !important;
}

.pl-5,
.px-5
{
    padding-left: 4rem !important;
}

.m-auto
{
    margin: auto !important;
}

.mt-auto,
.my-auto
{
    margin-top: auto !important;
}

.mr-auto,
.mx-auto
{
    margin-right: auto !important;
}

.mb-auto,
.my-auto
{
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto
{
    margin-left: auto !important;
}

@media (min-width: 576px)
{
    .m-sm-0
    {
        margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0
    {
        margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0
    {
        margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0
    {
        margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0
    {
        margin-left: 0 !important;
    }
    .m-sm-1
    {
        margin: 1rem !important;
    }
    .mt-sm-1,
    .my-sm-1
    {
        margin-top: 1rem !important;
    }
    .mr-sm-1,
    .mx-sm-1
    {
        margin-right: 1rem !important;
    }
    .mb-sm-1,
    .my-sm-1
    {
        margin-bottom: 1rem !important;
    }
    .ml-sm-1,
    .mx-sm-1
    {
        margin-left: 1rem !important;
    }
    .m-sm-2
    {
        margin: 1.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2
    {
        margin-top: 1.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2
    {
        margin-right: 1.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2
    {
        margin-left: 1.5rem !important;
    }
    .m-sm-3
    {
        margin: 3rem !important;
    }
    .mt-sm-3,
    .my-sm-3
    {
        margin-top: 3rem !important;
    }
    .mr-sm-3,
    .mx-sm-3
    {
        margin-right: 3rem !important;
    }
    .mb-sm-3,
    .my-sm-3
    {
        margin-bottom: 3rem !important;
    }
    .ml-sm-3,
    .mx-sm-3
    {
        margin-left: 3rem !important;
    }
    .m-sm-4
    {
        margin: 3.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4
    {
        margin-top: 3.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4
    {
        margin-right: 3.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4
    {
        margin-bottom: 3.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4
    {
        margin-left: 3.5rem !important;
    }
    .m-sm-5
    {
        margin: 4rem !important;
    }
    .mt-sm-5,
    .my-sm-5
    {
        margin-top: 4rem !important;
    }
    .mr-sm-5,
    .mx-sm-5
    {
        margin-right: 4rem !important;
    }
    .mb-sm-5,
    .my-sm-5
    {
        margin-bottom: 4rem !important;
    }
    .ml-sm-5,
    .mx-sm-5
    {
        margin-left: 4rem !important;
    }
    .p-sm-0
    {
        padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0
    {
        padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0
    {
        padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0
    {
        padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0
    {
        padding-left: 0 !important;
    }
    .p-sm-1
    {
        padding: 1rem !important;
    }
    .pt-sm-1,
    .py-sm-1
    {
        padding-top: 1rem !important;
    }
    .pr-sm-1,
    .px-sm-1
    {
        padding-right: 1rem !important;
    }
    .pb-sm-1,
    .py-sm-1
    {
        padding-bottom: 1rem !important;
    }
    .pl-sm-1,
    .px-sm-1
    {
        padding-left: 1rem !important;
    }
    .p-sm-2
    {
        padding: 1.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2
    {
        padding-top: 1.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2
    {
        padding-right: 1.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2
    {
        padding-left: 1.5rem !important;
    }
    .p-sm-3
    {
        padding: 3rem !important;
    }
    .pt-sm-3,
    .py-sm-3
    {
        padding-top: 3rem !important;
    }
    .pr-sm-3,
    .px-sm-3
    {
        padding-right: 3rem !important;
    }
    .pb-sm-3,
    .py-sm-3
    {
        padding-bottom: 3rem !important;
    }
    .pl-sm-3,
    .px-sm-3
    {
        padding-left: 3rem !important;
    }
    .p-sm-4
    {
        padding: 3.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4
    {
        padding-top: 3.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4
    {
        padding-right: 3.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4
    {
        padding-bottom: 3.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4
    {
        padding-left: 3.5rem !important;
    }
    .p-sm-5
    {
        padding: 4rem !important;
    }
    .pt-sm-5,
    .py-sm-5
    {
        padding-top: 4rem !important;
    }
    .pr-sm-5,
    .px-sm-5
    {
        padding-right: 4rem !important;
    }
    .pb-sm-5,
    .py-sm-5
    {
        padding-bottom: 4rem !important;
    }
    .pl-sm-5,
    .px-sm-5
    {
        padding-left: 4rem !important;
    }
    .m-sm-auto
    {
        margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto
    {
        margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto
    {
        margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto
    {
        margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 768px)
{
    .m-md-0
    {
        margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0
    {
        margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0
    {
        margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0
    {
        margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0
    {
        margin-left: 0 !important;
    }
    .m-md-1
    {
        margin: 1rem !important;
    }
    .mt-md-1,
    .my-md-1
    {
        margin-top: 1rem !important;
    }
    .mr-md-1,
    .mx-md-1
    {
        margin-right: 1rem !important;
    }
    .mb-md-1,
    .my-md-1
    {
        margin-bottom: 1rem !important;
    }
    .ml-md-1,
    .mx-md-1
    {
        margin-left: 1rem !important;
    }
    .m-md-2
    {
        margin: 1.5rem !important;
    }
    .mt-md-2,
    .my-md-2
    {
        margin-top: 1.5rem !important;
    }
    .mr-md-2,
    .mx-md-2
    {
        margin-right: 1.5rem !important;
    }
    .mb-md-2,
    .my-md-2
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-md-2,
    .mx-md-2
    {
        margin-left: 1.5rem !important;
    }
    .m-md-3
    {
        margin: 3rem !important;
    }
    .mt-md-3,
    .my-md-3
    {
        margin-top: 3rem !important;
    }
    .mr-md-3,
    .mx-md-3
    {
        margin-right: 3rem !important;
    }
    .mb-md-3,
    .my-md-3
    {
        margin-bottom: 3rem !important;
    }
    .ml-md-3,
    .mx-md-3
    {
        margin-left: 3rem !important;
    }
    .m-md-4
    {
        margin: 3.5rem !important;
    }
    .mt-md-4,
    .my-md-4
    {
        margin-top: 3.5rem !important;
    }
    .mr-md-4,
    .mx-md-4
    {
        margin-right: 3.5rem !important;
    }
    .mb-md-4,
    .my-md-4
    {
        margin-bottom: 3.5rem !important;
    }
    .ml-md-4,
    .mx-md-4
    {
        margin-left: 3.5rem !important;
    }
    .m-md-5
    {
        margin: 4rem !important;
    }
    .mt-md-5,
    .my-md-5
    {
        margin-top: 4rem !important;
    }
    .mr-md-5,
    .mx-md-5
    {
        margin-right: 4rem !important;
    }
    .mb-md-5,
    .my-md-5
    {
        margin-bottom: 4rem !important;
    }
    .ml-md-5,
    .mx-md-5
    {
        margin-left: 4rem !important;
    }
    .p-md-0
    {
        padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0
    {
        padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0
    {
        padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0
    {
        padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0
    {
        padding-left: 0 !important;
    }
    .p-md-1
    {
        padding: 1rem !important;
    }
    .pt-md-1,
    .py-md-1
    {
        padding-top: 1rem !important;
    }
    .pr-md-1,
    .px-md-1
    {
        padding-right: 1rem !important;
    }
    .pb-md-1,
    .py-md-1
    {
        padding-bottom: 1rem !important;
    }
    .pl-md-1,
    .px-md-1
    {
        padding-left: 1rem !important;
    }
    .p-md-2
    {
        padding: 1.5rem !important;
    }
    .pt-md-2,
    .py-md-2
    {
        padding-top: 1.5rem !important;
    }
    .pr-md-2,
    .px-md-2
    {
        padding-right: 1.5rem !important;
    }
    .pb-md-2,
    .py-md-2
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-md-2,
    .px-md-2
    {
        padding-left: 1.5rem !important;
    }
    .p-md-3
    {
        padding: 3rem !important;
    }
    .pt-md-3,
    .py-md-3
    {
        padding-top: 3rem !important;
    }
    .pr-md-3,
    .px-md-3
    {
        padding-right: 3rem !important;
    }
    .pb-md-3,
    .py-md-3
    {
        padding-bottom: 3rem !important;
    }
    .pl-md-3,
    .px-md-3
    {
        padding-left: 3rem !important;
    }
    .p-md-4
    {
        padding: 3.5rem !important;
    }
    .pt-md-4,
    .py-md-4
    {
        padding-top: 3.5rem !important;
    }
    .pr-md-4,
    .px-md-4
    {
        padding-right: 3.5rem !important;
    }
    .pb-md-4,
    .py-md-4
    {
        padding-bottom: 3.5rem !important;
    }
    .pl-md-4,
    .px-md-4
    {
        padding-left: 3.5rem !important;
    }
    .p-md-5
    {
        padding: 4rem !important;
    }
    .pt-md-5,
    .py-md-5
    {
        padding-top: 4rem !important;
    }
    .pr-md-5,
    .px-md-5
    {
        padding-right: 4rem !important;
    }
    .pb-md-5,
    .py-md-5
    {
        padding-bottom: 4rem !important;
    }
    .pl-md-5,
    .px-md-5
    {
        padding-left: 4rem !important;
    }
    .m-md-auto
    {
        margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto
    {
        margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto
    {
        margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto
    {
        margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 992px)
{
    .m-lg-0
    {
        margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0
    {
        margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0
    {
        margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0
    {
        margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0
    {
        margin-left: 0 !important;
    }
    .m-lg-1
    {
        margin: 1rem !important;
    }
    .mt-lg-1,
    .my-lg-1
    {
        margin-top: 1rem !important;
    }
    .mr-lg-1,
    .mx-lg-1
    {
        margin-right: 1rem !important;
    }
    .mb-lg-1,
    .my-lg-1
    {
        margin-bottom: 1rem !important;
    }
    .ml-lg-1,
    .mx-lg-1
    {
        margin-left: 1rem !important;
    }
    .m-lg-2
    {
        margin: 1.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2
    {
        margin-top: 1.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2
    {
        margin-right: 1.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2
    {
        margin-left: 1.5rem !important;
    }
    .m-lg-3
    {
        margin: 3rem !important;
    }
    .mt-lg-3,
    .my-lg-3
    {
        margin-top: 3rem !important;
    }
    .mr-lg-3,
    .mx-lg-3
    {
        margin-right: 3rem !important;
    }
    .mb-lg-3,
    .my-lg-3
    {
        margin-bottom: 3rem !important;
    }
    .ml-lg-3,
    .mx-lg-3
    {
        margin-left: 3rem !important;
    }
    .m-lg-4
    {
        margin: 3.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4
    {
        margin-top: 3.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4
    {
        margin-right: 3.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4
    {
        margin-bottom: 3.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4
    {
        margin-left: 3.5rem !important;
    }
    .m-lg-5
    {
        margin: 4rem !important;
    }
    .mt-lg-5,
    .my-lg-5
    {
        margin-top: 4rem !important;
    }
    .mr-lg-5,
    .mx-lg-5
    {
        margin-right: 4rem !important;
    }
    .mb-lg-5,
    .my-lg-5
    {
        margin-bottom: 4rem !important;
    }
    .ml-lg-5,
    .mx-lg-5
    {
        margin-left: 4rem !important;
    }
    .p-lg-0
    {
        padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0
    {
        padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0
    {
        padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0
    {
        padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0
    {
        padding-left: 0 !important;
    }
    .p-lg-1
    {
        padding: 1rem !important;
    }
    .pt-lg-1,
    .py-lg-1
    {
        padding-top: 1rem !important;
    }
    .pr-lg-1,
    .px-lg-1
    {
        padding-right: 1rem !important;
    }
    .pb-lg-1,
    .py-lg-1
    {
        padding-bottom: 1rem !important;
    }
    .pl-lg-1,
    .px-lg-1
    {
        padding-left: 1rem !important;
    }
    .p-lg-2
    {
        padding: 1.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2
    {
        padding-top: 1.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2
    {
        padding-right: 1.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2
    {
        padding-left: 1.5rem !important;
    }
    .p-lg-3
    {
        padding: 3rem !important;
    }
    .pt-lg-3,
    .py-lg-3
    {
        padding-top: 3rem !important;
    }
    .pr-lg-3,
    .px-lg-3
    {
        padding-right: 3rem !important;
    }
    .pb-lg-3,
    .py-lg-3
    {
        padding-bottom: 3rem !important;
    }
    .pl-lg-3,
    .px-lg-3
    {
        padding-left: 3rem !important;
    }
    .p-lg-4
    {
        padding: 3.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4
    {
        padding-top: 3.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4
    {
        padding-right: 3.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4
    {
        padding-bottom: 3.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4
    {
        padding-left: 3.5rem !important;
    }
    .p-lg-5
    {
        padding: 4rem !important;
    }
    .pt-lg-5,
    .py-lg-5
    {
        padding-top: 4rem !important;
    }
    .pr-lg-5,
    .px-lg-5
    {
        padding-right: 4rem !important;
    }
    .pb-lg-5,
    .py-lg-5
    {
        padding-bottom: 4rem !important;
    }
    .pl-lg-5,
    .px-lg-5
    {
        padding-left: 4rem !important;
    }
    .m-lg-auto
    {
        margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto
    {
        margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto
    {
        margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto
    {
        margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto
    {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px)
{
    .m-xl-0
    {
        margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0
    {
        margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0
    {
        margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0
    {
        margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0
    {
        margin-left: 0 !important;
    }
    .m-xl-1
    {
        margin: 1rem !important;
    }
    .mt-xl-1,
    .my-xl-1
    {
        margin-top: 1rem !important;
    }
    .mr-xl-1,
    .mx-xl-1
    {
        margin-right: 1rem !important;
    }
    .mb-xl-1,
    .my-xl-1
    {
        margin-bottom: 1rem !important;
    }
    .ml-xl-1,
    .mx-xl-1
    {
        margin-left: 1rem !important;
    }
    .m-xl-2
    {
        margin: 1.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2
    {
        margin-top: 1.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2
    {
        margin-right: 1.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2
    {
        margin-bottom: 1.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2
    {
        margin-left: 1.5rem !important;
    }
    .m-xl-3
    {
        margin: 3rem !important;
    }
    .mt-xl-3,
    .my-xl-3
    {
        margin-top: 3rem !important;
    }
    .mr-xl-3,
    .mx-xl-3
    {
        margin-right: 3rem !important;
    }
    .mb-xl-3,
    .my-xl-3
    {
        margin-bottom: 3rem !important;
    }
    .ml-xl-3,
    .mx-xl-3
    {
        margin-left: 3rem !important;
    }
    .m-xl-4
    {
        margin: 3.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4
    {
        margin-top: 3.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4
    {
        margin-right: 3.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4
    {
        margin-bottom: 3.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4
    {
        margin-left: 3.5rem !important;
    }
    .m-xl-5
    {
        margin: 4rem !important;
    }
    .mt-xl-5,
    .my-xl-5
    {
        margin-top: 4rem !important;
    }
    .mr-xl-5,
    .mx-xl-5
    {
        margin-right: 4rem !important;
    }
    .mb-xl-5,
    .my-xl-5
    {
        margin-bottom: 4rem !important;
    }
    .ml-xl-5,
    .mx-xl-5
    {
        margin-left: 4rem !important;
    }
    .p-xl-0
    {
        padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0
    {
        padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0
    {
        padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0
    {
        padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0
    {
        padding-left: 0 !important;
    }
    .p-xl-1
    {
        padding: 1rem !important;
    }
    .pt-xl-1,
    .py-xl-1
    {
        padding-top: 1rem !important;
    }
    .pr-xl-1,
    .px-xl-1
    {
        padding-right: 1rem !important;
    }
    .pb-xl-1,
    .py-xl-1
    {
        padding-bottom: 1rem !important;
    }
    .pl-xl-1,
    .px-xl-1
    {
        padding-left: 1rem !important;
    }
    .p-xl-2
    {
        padding: 1.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2
    {
        padding-top: 1.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2
    {
        padding-right: 1.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2
    {
        padding-bottom: 1.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2
    {
        padding-left: 1.5rem !important;
    }
    .p-xl-3
    {
        padding: 3rem !important;
    }
    .pt-xl-3,
    .py-xl-3
    {
        padding-top: 3rem !important;
    }
    .pr-xl-3,
    .px-xl-3
    {
        padding-right: 3rem !important;
    }
    .pb-xl-3,
    .py-xl-3
    {
        padding-bottom: 3rem !important;
    }
    .pl-xl-3,
    .px-xl-3
    {
        padding-left: 3rem !important;
    }
    .p-xl-4
    {
        padding: 3.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4
    {
        padding-top: 3.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4
    {
        padding-right: 3.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4
    {
        padding-bottom: 3.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4
    {
        padding-left: 3.5rem !important;
    }
    .p-xl-5
    {
        padding: 4rem !important;
    }
    .pt-xl-5,
    .py-xl-5
    {
        padding-top: 4rem !important;
    }
    .pr-xl-5,
    .px-xl-5
    {
        padding-right: 4rem !important;
    }
    .pb-xl-5,
    .py-xl-5
    {
        padding-bottom: 4rem !important;
    }
    .pl-xl-5,
    .px-xl-5
    {
        padding-left: 4rem !important;
    }
    .m-xl-auto
    {
        margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto
    {
        margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto
    {
        margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto
    {
        margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto
    {
        margin-left: auto !important;
    }
}

.text-monospace
{
    font-family: 'Quicksand', Georgia, 'Times New Roman', Times, serif;
}

.text-justify
{
    text-align: justify !important;
}

.text-nowrap
{
    white-space: nowrap !important;
}

.text-truncate
{
    overflow: hidden;

    white-space: nowrap; 
    text-overflow: ellipsis;
}

.text-left
{
    text-align: left !important;
}

.text-right
{
    text-align: right !important;
}

.text-center
{
    text-align: center !important;
}

@media (min-width: 576px)
{
    .text-sm-left
    {
        text-align: left !important;
    }
    .text-sm-right
    {
        text-align: right !important;
    }
    .text-sm-center
    {
        text-align: center !important;
    }
}

@media (min-width: 768px)
{
    .text-md-left
    {
        text-align: left !important;
    }
    .text-md-right
    {
        text-align: right !important;
    }
    .text-md-center
    {
        text-align: center !important;
    }
}

@media (min-width: 992px)
{
    .text-lg-left
    {
        text-align: left !important;
    }
    .text-lg-right
    {
        text-align: right !important;
    }
    .text-lg-center
    {
        text-align: center !important;
    }
}

@media (min-width: 1200px)
{
    .text-xl-left
    {
        text-align: left !important;
    }
    .text-xl-right
    {
        text-align: right !important;
    }
    .text-xl-center
    {
        text-align: center !important;
    }
}

.text-lowercase
{
    text-transform: lowercase !important;
}

.text-uppercase
{
    text-transform: uppercase !important;
}

.text-capitalize
{
    text-transform: capitalize !important;
}

.font-weight-light
{
    font-weight: 300 !important;
}

.font-weight-normal
{
    font-weight: 400 !important;
}

.font-weight-bold
{
    font-weight: 700 !important;
}

.font-italic
{
    font-style: italic !important;
}

.text-white
{
    color: #fff !important;
}

.text-primary
{
    color: #666ee8 !important;
}

a.text-primary:hover,
a.text-primary:focus
{
    color: #3a44e1 !important;
}

.text-secondary
{
    color: #6b6f82 !important;
}

a.text-secondary:hover,
a.text-secondary:focus
{
    color: #545766 !important;
}

.text-success
{
    color: #28d094 !important;
}

a.text-success:hover,
a.text-success:focus
{
    color: #20a576 !important;
}

.text-info
{
    color: #1e9ff2 !important;
}

a.text-info:hover,
a.text-info:focus
{
    color: #0c84d1 !important;
}

.text-warning
{
    color: #ff9149 !important;
}

a.text-warning:hover,
a.text-warning:focus
{
    color: #ff7216 !important;
}

.text-danger
{
    color: #ff4961 !important;
}

a.text-danger:hover,
a.text-danger:focus
{
    color: #ff1635 !important;
}

.text-light
{
    color: #babfc7 !important;
}

a.text-light:hover,
a.text-light:focus
{
    color: #9ea5b0 !important;
}

.text-dark
{
    color: #464855 !important;
}

a.text-dark:hover,
a.text-dark:focus
{
    color: #2f3039 !important;
}

.text-body
{
    color: #6b6f82 !important;
}

.text-muted
{
    color: #6b6f82 !important;
}

.text-black-50
{
    color: rgba(0, 0, 0, .5) !important;
}

.text-white-50
{
    color: rgba(255, 255, 255, .5) !important;
}

.text-hide
{
    font: 0/0 a;

    color: transparent;
    border: 0; 
    background-color: transparent;
    text-shadow: none;
}

.visible
{
    visibility: visible !important;
}

.invisible
{
    visibility: hidden !important;
}

@media print
{
    *,
    *::before,
    *::after
    {
        -webkit-box-shadow: none !important;
                box-shadow: none !important; 
        text-shadow: none !important;
    }
    a:not(.btn)
    {
        text-decoration: underline;
    }
    abbr[title]::after
    {
        content: ' (' attr(title) ')';
    }
    pre
    {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote
    {
        border: 1px solid #adb5bd;

        page-break-inside: avoid;
    }
    thead
    {
        display: table-header-group;
    }
    tr,
    img
    {
        page-break-inside: avoid;
    }
    p,
    h2,
    h3
    {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3
    {
        page-break-after: avoid;
    }
    @page
    {
        size: a3;
    }
    body
    {
        min-width: 992px !important;
    }
    .container
    {
        min-width: 992px !important;
    }
    .navbar
    {
        display: none;
    }
    .badge
    {
        border: 1px solid #000;
    }
    .table
    {
        border-collapse: collapse !important;
    }
    .table td,
    .table th
    {
        background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td
    {
        border: 1px solid #626e82 !important;
    }
    .table-dark
    {
        color: inherit;
    }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody
    {
        border-color: #626e82;
    }
    .table .thead-dark th
    {
        color: inherit;
        border-color: #626e82;
    }
}
